.main-container {
  background: #f0eff4;
  padding-bottom: 20px;
}
.pp-back {
  width: 100%;
  height: 400px;
  /* padding-top: 50px; */
}

.pp-back img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.jc-center .MuiFormControlLabel-root {
  align-items: start !important;
}
.jc-center .MuiTypography-root {
  text-align: left;
}
.pl-white-box {
  width: 100%;
  min-height: 581px;
  border-radius: 10px;
  background: #fff;
  max-width: 1012px;
  margin: -100px auto 30px;
  position: relative;
  padding: 100px 15px;
}
.rmdp-container .rmdp-input
{
  min-height: 55.97px;
  border-radius: 4px;
}
.rmdp-container
{
  margin-top: 15px;
}
.lot-radio {
  position: relative;
  width: 180px;
  height: 215px;
}

.lot-radio label {
  width: 100%;
}

.lot-radio input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
}

.lot-radio input[type="radio"] ~ label .lot-box {
  width: 100%;
  height: 180px;
  border: 2px dashed #798193;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.lot-radio input[type="radio"]:checked ~ label .lot-box {
  background: #798193;
  border: 1px solid #798193;
}

.lot-radio input[type="radio"]:checked ~ label .lot-box img {
  filter: brightness(0) invert(1);
}

.pl-label {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #21285d;
  padding: 10px 0;
  line-height: 1;
}

.lot-select-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  max-width: 420px;
  margin: 50px auto;
}

.pl-head {
  font-family: "Arial";
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #21285d;
  margin-bottom: 50px;
}

.pl-shead {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #21285d;
  margin-bottom: 50px;
}

.pl-btn {
  width: 140px;
  margin: 0 auto;
}
.pl-btn .material-icons {
  margin-left: 10px;
}

.pl-form {
  max-width: 900px;
  margin: 0 auto;
}

.MuiFormGroup-root.reserve-price,
.MuiFormGroup-root.fd-row {
  flex-direction: row;
}

.reserve-price .MuiFormControlLabel-root {
  min-width: 40%;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #21285d;
}
.multVideo .video-react.video-react-fluid {
  padding-top: 57% !important;
}
.MuiOutlinedInput-input {
  font-size: 15px;
  line-height: 25px;
  text-align: left;
  color: #929292;
}
.pl-form .form-group .MuiFormControl-root
{
  margin-top: 16px;
}
.form-group .MuiFormControl-root
{
  max-width: unset;
}
.pl-form .form-group .MuiFormLabel-root
{
  background-color: #fff;
  padding: 0px 2px;
  margin-top: 8px;
}
.pl-form .form-group .MuiFormLabel-root.MuiInputLabel-shrink
{
  margin-top: 0px;
}
.pl-form .form-group .MuiFormControl-root .MuiOutlinedInput-root,.pl-form .form-group .MuiFormControl-root
{
  min-height: 55.98px;
}
.MuiFormLabel-root.pp-rc-label {
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  color: #21285d;
}

.MuiInputAdornment-positionEnd {
  margin-left: 8px;
  color: #727272;
  background: #f8f8f8;
  min-height: 54px;
  position: absolute;
  z-index: 99;
  width: 38px;
  top: 1px;
  right: 1px;
  font-size: 16px;
  justify-content: center;
  border-radius: 0 2px 2px 0;
}

.MuiInputAdornment-positionEnd .material-icons {
  font-size: 16px;
}

.form-ghead {
  font-family: "Arial";
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: left;
  color: #142850;
  margin-bottom: 10px;
}

.pl-btn.trans-btn .material-icons {
  margin: 0 10px 0 0;
  width: 20px;
}

.btns-wrap > div {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.step-wrapper {
  width: 100%;
  max-width: 550px;
  margin: 30px auto 75px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.step-wrapper.multi-items-step {
  max-width: 750px;
}

.step-circle {
  width: 70px;
  height: 70px;
  background: #fff;
  border: 2px solid #cfcfcf;
  border-radius: 35px;
  padding: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #cfcfcf;
  margin: 0 auto 25px;
  position: relative;
  z-index: 110;
}
.MuiPopover-root
{
  z-index: 1298 !important;
}
.enterBreed textarea
{
  min-width: 226px;
  width: 100%;
}
.step-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #cfcfcf;
}

.active .step-circle {
  color: var(--primColor);
  border-color: var(--primColor);
}

.completed .step-circle {
  background: var(--primColor);
  border-color: var(--primColor);
  color: #fff;
}

.active .step-label,
.completed .step-label {
  color: var(--primColor);
}

.step:after {
  position: absolute;
  width: 450px;
  border-bottom: 2px dashed #cfcfcf;
  top: 35px;
  left: 0;
  right: 0;
  margin: auto;
}

.step.completed:after {
  border-bottom: 2px dashed var(--primColor);
}

.multi-items-step .step1:after {
  width: 80%;
  left: 60px;
  right: inherit;
}

.multi-items-step .step2:after {
  display: none;
}

.aa-item,
.aa-item:hover,
.aa-item:focus {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  color: var(--primColor);
}

.aa-item span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #142850 !important;
  border-width: 1px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #142850 !important;
}

.main-container .addItem {
  display: flex;
  justify-content: flex-end;
}

.main-container .addItem a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--primColor);
}

.main-container .addItem span {
  padding-right: 10px;
}

/* Lot choice material UI Error message update */

.MuiAlert-root {
  justify-content: center;
}
.MuiAlert-outlinedError.MuiAlert-root {
  padding: 0;
}

.btns-wrap > div button {
  border-radius: 15px;
}

.sdec-head {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.seller-note p,
.sdec-note li,
.seller-note table td {
  font-size: 14px;
  text-align: left;
  margin-bottom: 12px;
}

.seller-note table td {
  margin-bottom: 0;
  padding: 5px 10px;
}

.pl-btns {
  height: 60px;
  max-width: 200px;
  margin: 0 auto;
}

.jc-center .MuiFormGroup-row {
  justify-content: center;
}

.CountyLaunchNotice {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  background: #ff98001a;
  color: #f19309;
  padding: 8px;
  border: 1px dashed #ffba55;
  border-radius: 5px;
}

.CountyLaunchNotice .material-icons {
  padding-right: 10px;
}
.notesCls {
  /* width: calc(100% - 100% * 0.4) !important; */
  flex: none !important;
}

/* Responsive */

@media (max-width: 767px) {
  .lot-select-wrapper {
    padding: 0;
  }
  .pp-back {
    width: 100%;
    height: 200px;
    padding-top: 80px;
  }
  .form-ghead {
    font-size: 19px;
  }
  .herd-number a {
    text-align: right !important;
  }
  .pp-back img {
    height: 100%;
  }
  .pl-white-box {
    margin: 0 auto 30px;
    padding: 40px 15px;
  }

  .lot-radio {
    width: 48%;
    max-width: 180px;
  }

  .lot-radio .lot-label {
    display: block;
  }

  .lot-radio input[type="radio"] ~ label .lot-box {
    height: 150px;
  }

  .step-circle {
    width: 50px;
    height: 50px;
    padding: 4px;
    font-weight: 500;
    font-size: 20px;
  }
  .step-wrapper.multi-items-step {
    max-width: 300px;
  }

  .multi-items-step .step1:after {
    top: 25px;
    width: 190px;
  }

  .btns-wrap > div {
    margin: 0;
  }

  .btns-wrap > div + div {
    margin-left: 5px;
  }
}

@media (max-width: 600px) {
  .termsCond .custom-modal .modal-dialog {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .termsCond .custom-modal .modal-header {
    margin: 0;
    height: 0;
    padding: 0 !important;
  }
  .termsCond .modal-content p {
    margin-bottom: 0;
  }
  .termsCond .custom-modal .modal-header button {
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;
    z-index: 9999;
  }
  .termsCond .modal {
    top: 50px;
  }
  .listingType .custom-modal .modal-content {
    background-color: rgb(255 255 255);
  }
  .listingType.MuiFormControl-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .listingType .modal-content p {
    font-size: 15px;
  }
  .listingType .custom-modal .modal-body {
    padding: 14px;
  }

  .listingType .custom-modal .modal-body .MuiFormControlLabel-root {
    margin: 0;
  }
  .listingType .MuiFormLabel-root.pp-rc-label {
    display: contents;
    font-size: 14px;
  }
  .listingType .listing-under .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
  .reserve-price .MuiFormControlLabel-root {
    min-width: auto;
    margin-bottom: 0;
  }
  .listingType .listing-under .MuiFormControlLabel-root .MuiTypography-root,
  .reserve-price .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 13px;
  }
  .icbfColumn .indexVal {
    font-size: 18px;
    font-weight: 400;
    margin-right: 10px;
  }
}
@media (max-width:480px)
{
  .pl-form .form-group .MuiFormLabel-root.MuiInputLabel-shrink
  {
    margin-top: -3px;
  }
}
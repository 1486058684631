.catlouge-head,
.catlouge-head1 {
	font-family:'Arial';
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #21285D;
    margin: 30px auto 60px;
}

.catlouge-head1 {
	margin:50px auto 20px;
}

.catlouge-title { 
	font-weight: 500;
	font-size: 18px;
	text-align:center;
	color: #21285D; 
	margin:15px 0;
}

.catlouge-img {
	width:100%;
	height:175px;
	object-fit:cover;
	border-radius:5px;
	object-position:center;
}

.catlouge-container {
    margin-bottom: -6.5rem;
    padding: 120px 15px;
    text-align: left;
    min-height: calc(100vh - 290px);
    background:#fff;
}

.catlouge-container .container {
	max-width:1240px;
}

.catlouge-container .container .row {
	margin:0 -5px;
}

.catlouge-container .container .col-md-3 {
	padding:0 5px;
}

.catlouge-descp,
.catlouge-label {
	font-size: 16px;
    max-width: 900px;
    margin: 0 auto 20px;
    text-align:center;
}

.catlouge-label .material-icons{
	display:inline-block;
	vertical-align:middle;
	margin-right:10px;
}

.catlouge-wrapper {
	padding-top:90px;
}

.catlouge-banner {
	position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catlouge-banner-img {
	position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

.catlouge-head {
	width: 100%;
    text-align: center;
    position: relative;
    z-index: 110;
    color: #fff;
    font-family:'Arial';
    font-size: 30px;
    font-weight: bold;
    letter-spacing:0.15rem;
    padding: 10px 15px;
    text-transform:uppercase;
}

.catlouge-banner:after {
  width:100%;
  position:absolute;
  height:100%;
  top:0;
  left:0;
  background:rgba(0,0,0,0.5);
  z-index:99;
}

.catlouge-head:after {
	border-bottom:1px solid #76BC43;
	width: 80%;
    max-width: 200px;
    margin: 0 auto;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
}

.card-catlouge button {
    border:none;
    background:none;
}

@media(max-width:767px) {
    .catlouge-wrapper {
        padding-top: 80px;
    }

    .catlouge-banner {
        height:120px;
    }

    .catlouge-head {
        font-size:20px;
    }

    .ccs-wrapper {
        padding:80px 0 20px;
        margin-bottom:0;
    }

    .ccs-wrapper .catlouge-head1 {
        margin:20px auto 10px;
        font-size:18px;
    }

    .ccs-wrapper .row {
        margin:0 -5px;
    }

    .ccs-wrapper .row .col-6 {
        padding:0 5px;
    }

    .ccs-wrapper .catlouge-img {
        height:125px;
    }

    .ccs-wrapper .catlouge-title {
        font-size:15px;
    }
}

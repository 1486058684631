.MuiDialogActions-root {
    justify-content: normal!important;
    padding-right: 180px!important;
    padding-left: 60px!important;
    
}
.react-tel-input {
    height: 56px !important;
    margin-top: 15px !important;
}
.react-tel-input .form-control {
    width: 100% !important;
    height: 56px !important;
    
}
.modalCTA button span
{
    color: #21285d;
}
.pre_bidding
{
    margin-bottom: 13px;
}
.option_Enable
{
    width: 80%;
}
/* .page.MuiFormHelperText-root {
    text-align: center !important;
} */
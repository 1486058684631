.sprofile-box {
	background: #f0eff4;
    display: flex;
    padding: 20px 40px;
    margin: 120px auto 80px;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    border-radius: 10px;
    position:relative;
}

.sprofile-box > div:first-child {
	width:250px;
}

.sprofile-box > div.row {
   flex:1;
   text-align:left;
   font-size:16px;
}

.sp-img-wrap {
	margin-top:80px;
}

.sp-img-wrap img {
	width:150px;
	height:150px;
	border-radius:50%;
	border:5px solid #fff;
}

.sp-head-lbl {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #737373;
    margin-bottom: .25rem;
}

.sp-descp {
    font-weight: 300;
    font-size: 1rem;
    text-align: left;
    color: #21285d;
    font-family: "Arial";
}

.sprofile-box .light-red .fas {
	margin-right:4px;
}

.sp-user-name {
    line-height: 1.5rem;
    font-size: 1.5rem;
    margin: 12px 0 5px;
    font-family: 'Arial';
    font-weight: 700;
    color: #21285d;
}


.ur-img {
	width:40px;
	height:40px;
	border-radius:50%;
	margin-right:10px;
}



.sort-by-sp .customDropdown {
	display:inline-block;
	width:auto;
	max-width:200px;
}

.searchGrid .auctionCard {
  /* margin-right: 10px; */
  max-width:298px;
}

.reviews-wrapper .card {
  width: 100%;
  height:auto;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #eaeaea;
  max-width:100%;
  margin: 10px auto;
  font-family:'Arial';
}

.reviews-wrapper .card-text,
.reviews-wrapper .card-title {
  font-weight: normal;
  font-size:14px;
  text-align: left;
  color: #21285d;
}

.reviews-wrapper .user_img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
 
.sort-by {
  max-width:100%;
}


.sprofile-box .favoriteCheck {
  position: absolute;
  left: 10px;
  top: 10px;
}

.sprofile-box .favoriteCheck label {
  background:transparent;
}
.footer {
  background:#fff;
  padding-top: 45px;
  padding-bottom: 45px; 
  border-top:1px solid #d5d5d5;
  position: relative;
  z-index: 1000;
}
 

.footer .footerLt .footerSocialLinks a + a {
  margin-left:15px;
}

#nuacom-call-widget{
  z-index: 1000;
  bottom: 5px;
  display: none;
}

.footer .footerLt .footerSocialLinks a {
  border: 1px solid var(--primColor);
  border: 1px solid var(--primColor);
  border-radius: 100%;
  padding: 8px;
  width: 42px;
  height: 42px;
}

.footer .footerLt .footerSocialLinks a .fab {
  font-size: 22px;
  line-height: 26px;
  color:#76bc43;
}

.footer .footerRt ul {
  margin-bottom: 35px;
  list-style: none;
}
.footer .footerRt ul li:not(first-child) {
  margin-left: 30px;
}

.footer .footerRt ul li a {
  font-size: 1.6em;
  color: var(--secColor);
  font-family: "Arial";
}

.footer .footerRt p {
  font-size: 1.5em;
  color: var(--secColor);
  margin: 0;
  text-align: right;
  margin-top: 50px;
}

.footerLogo {
  text-align: left;
  margin: 0 0 25px;
}
.play-img{
    width: 150px;
    height: 50px;
    padding-right: 10px;
    float: right;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .footer .footerRt ul li:not(first-child) {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .footerLt {
    max-width: 40%;
  }
}

@media (max-width: 767px) {
  .footerRt ul {
    display: none !important;
  }
  .footer {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footerLogo {
    text-align: center;
    margin: 0 0 25px;
  }

  .footerLt,
  .footerRt {
    width: 100%;
    text-align: center;
  }

  .footerSocialLinks {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .footer .customContainer {
    flex-wrap: wrap;
  }

  .footer .footerRt p {
    margin: 5px 0 0;
    text-align: center;
  }

  .ffHome .homeFoot .footer {
    margin-top: 0;
  }

  .cookieConsent + .mc-pt250 {
    padding-top:215px;
  }
}

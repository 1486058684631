.RadioBox{
    text-align: left;
}

.RadioBox .MuiFormControl-root{
    text-align: left;
}

.RadioBox .MuiFormGroup-root{
    flex-direction: row;
} 

.RadioBox legend.MuiFormLabel-root{
    font-size: 1.6em;
    color: var(--secColor);

}
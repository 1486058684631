/* .feedTable table thead th {
  font-size: 12px;
  background: #f5f5f5;
  color: #21285d;
  padding: 8px 12px;
  border-top: 1px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 1px solid #f5f5f5;
}
.feedTable table tbody td {
  font-size: 12px;
  background: #fff;
  color: #21285d;
  padding: 8px 12px;
  border-bottom: 1px solid #fff;
}
.feedTable .slick-slide
{
  width: 100%;
} */
.myFeedsContainer .db-bids-wrapper {
  padding: 0;
}
.myFeedsContainer
  .db-bids-wrapper
  .MuiTabs-root
  .MuiButtonBase-root.Mui-selected {
  color: #76bd43;
}
.feedTable
{
  max-height: 740px;
}
.feedTable .thHead,
.feedTable .tbBody {
  width: 100%;
  text-align: left;
  display: flex !important;
}

.feedTable .thHead {
  font-size: 12px;
  font-weight: 500;
  font-family: "Arial";
}
.feedTable .slick-list {
  height: 220px !important;
}
.feedTable .tbBody {
  font-size: 12px;
}
.feedTable .thHead>div,.feedTable .tbBody>div
{
  width: calc(100% / 4);
    display: inline-block;
    word-break: break-word;
    background: #f5f5f5;
    border: 1px solid #fff;
    padding: 5px;
    color: #21285d;
}
.myFeedsContainer .db-bids-wrapper .MuiTabs-root .MuiButtonBase-root {
  text-transform: capitalize;
  width: 50%;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}
.myFeedsContainer .MuiTabs-indicator {
  background-color: #76bd43;
}
.myFeedsContainer .MuiBox-root {
  padding: 15px 0;
}
.myFeedsContainer .db-cheader {
  display: none;
}
.myFeedsContainer .db-bids-wrapper .MuiTabs-root {
  border-bottom: 1px solid #e4e8ee;
}
.myFeedsContainer .db-bids-wrapper .playFair {
  display: none;
}

.myFeedsContainer .newsUContain {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
}
.myFeedsContainer .newsUContain .newsUCard {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  padding: 10px 15px;
  border-bottom: 1px solid #e2e2e2;
}
.myFeedsContainer .newsUContain .newsUCard .newsUPic {
  width: min(46px);
  overflow: hidden;
}
.myFeedsContainer .newsUContain .newsUCard{
  border: none;
}
.myFeedsContainer .newsUContain{
  overflow-y: hidden !important;
}
.myFeedsContainer .newsUContain h2{
  font-size: 20px;
}
.myFeedsContainer .newsUContain p{
  margin-bottom: 0;
}
.myFeedsContainer .newsUContain .newsUCard .newsUPic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.myFeedsContainer .newsUContain .newsUContent {
}
.myFeedsContainer .newsUContain .newsUContent .newsUTit {
  color: #1e293b;
  font-size: 14px;
  font-weight: 700;
}
.myFeedsContainer .newsUContain .newsUContent .newsUpara {
  font-size: 14px;
  color: #334155;
  font-weight: 400;
  margin: 0;
}
/* .feedTable th:nth-child(3),
.feedTable th:nth-child(4),
.feedTable th:nth-child(5),
.feedTable td:nth-child(3),
.feedTable td:nth-child(4),
.feedTable td:nth-child(5) {
  text-align: right;
} */
@media (max-width:768px){
  .myFeedsContainer .MuiBox-root .feedTable,.myFeedsContainer .newsUContain
  {
    max-height: 445px;
    overflow-y: auto;
    height: 100%;
  }
  
}
.address-card {
    position:relative;
    width: 100%
}

.address-card > button{
    width: 100%
}

.address-card input[type=radio] {
    position:absolute;
    width:100%;
    height:100%;
    opacity:0;
    z-index:99;
}


.ac-radio {
    width: 100%;
    height: 175px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed #b4baca;
    padding:1.5rem 0.25rem; 
    font-size:1rem;
    line-height:2rem; 
    color: #788094;

}

.ac-radio h6 { 
    font-weight: 500; 
    color: #444d63;
    max-width: 94%;
}

.ac-radio .material-icons {
    position: absolute;
    color: #DBD9E2;
    right: 2rem;
    top: 1.5rem;
}

.address-card input[type=radio]:checked ~ .ac-radio {
    background:#F9FFFC;
    border-color:var(--primColor);
}

.address-card input[type=radio]:checked ~ .ac-radio .material-icons {
  color:var(--primColor);
} 

.delivery-type {
    width: 96px;
    height: 30px;
    border-radius: 15px;
    background: var(--primColor);
    float: right;
    text-align: center; 
    font-weight: 500;
    font-size: 11px;
    line-height: 20px; 
    color: #fff;
    padding: 5px;
}

.ccard-wrap {
    height:175px;
    border-radius: 5px;
    background: linear-gradient(#5a5a5a 0%, #323335 49.33%, #000 100%);
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    position:relative;
    padding:1rem 0.75rem;
    color:#fff;
}

.map-svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:100%;
}

.card-num {
    padding: 26px 0 15px;
    text-align: center;
}

.card-num span {
    display:inline-block;
    vertical-align:middle;
    font-size:1.5rem;
    line-height:1;
    margin:0.25rem 0;
}

.card-num span + span {
    margin-left:15px;
}

.fs-12 {
  font-size:0.75rem
}

@media(max-width:991px) {
    .ac-radio {
        padding:20px 15px;
    }
}
@media(max-width: 1199px) {
    .card-num {
        padding: 10px 0 10px;
    }
}

.upload-file-wrapper {
	width:100%;
	height: 180px;
	border-radius: 5px;
	background: #fbfbfb;
	border:2px dashed #c9c5d7;
    position:relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.upload-file-wrapper input[type="file"] {
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	opacity:0;
	z-index:99;
	cursor:pointer;
}

.upload-file-wrapper > div {
	width:100%;
}

.ul-img-head {
	font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #737373;
    margin: 10px 0 0;
}

.ul-img-shead { 
	font-size: 14px;
	line-height: 25px; 
	color: #737373;
}
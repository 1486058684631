::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  border-radius: 25px;
}
::-webkit-scrollbar-track {
  background: #ebebeb;
}

:root {
  --primColor: #76bc43;
  --secColor: #21285d;
  font-size: 16px;
}

html,
body,
#root,
.App {
  min-height: 100%;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

.MuiTabScrollButton-root {
  display: inline-flex;
}

/* @font-face {
  font-family: "Arial";
  src: url("/fonts/Arial.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Arial.otf") format("opentype"),
    url("/fonts/Arial.woff") format("woff"),
    url("/fonts/Arial.ttf") format("truetype"),
    url("/fonts/Arial.svg#Arial") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brother-1816-Light";
  src: url("/fonts/Brother-1816-Light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Brother-1816-Light.otf") format("opentype"),
    url("/fonts/Brother-1816-Light.woff") format("woff"),
    url("/fonts/Brother-1816-Light.ttf") format("truetype"),
    url("/fonts/Brother-1816-Light.svg#Brother-1816-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aperto";
  src: url("/fonts/Aperto.eot");
  src: url("/fonts/Aperto.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Aperto.woff2") format("woff2"),
    url("/fonts/Aperto.woff") format("woff"),
    url("/fonts/Aperto.ttf") format("truetype"),
    url("/fonts/Aperto.otf") format("opentype"),
    url("/fonts/Aperto.svg#Aperto") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Arial";
  src: url("/fonts/Aperto-Bold.eot");
  src: url("/fonts/Aperto-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Aperto-Bold.woff2") format("woff2"),
    url("/fonts/Aperto-Bold.woff") format("woff"),
    url("/fonts/Aperto-Bold.ttf") format("truetype"),
    url("/fonts/Aperto-Bold.otf") format("opentype"),
    url("/fonts/Aperto-Bold.svg#Aperto-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
} */

@font-face {
  font-family: "Arial MT Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Extra Bold"),
    url("/fonts/Arial/ARIALMTEXTRABOLD.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Light";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Light"),
    url("/fonts/Arial/ARIALMTLIGHT.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Medium"),
    url("/fonts/Arial/ARIALMTMEDIUM.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Extra Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Extra Bold Italic"),
    url("/fonts/Arial/ARIALMTEXTRABOLDITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Light Italic"),
    url("/fonts/Arial/ARIALMTLIGHTITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Medium Italic"),
    url("/fonts/Arial/ARIALMTMEDIUMITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Black Italic"),
    url("/fonts/Arial/arialmtblackitalic.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Italic"),
    url("/fonts/Arial/ARIALMTITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Bold"),
    url("/fonts/Arial/ARIALBOLDMT.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Bold Italic"),
    url("/fonts/Arial/ARIALBOLDITALICMT.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Narrow Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Narrow Regular"),
    url("/fonts/Arial/ARIALMTNARROW.woff") format("woff");
}

@font-face {
  font-family: "Arial Rounded MT Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Rounded MT Regular"),
    url("/fonts/Arial/ARIALROUNDEDMT.woff") format("woff");
}

@font-face {
  font-family: "Arial Rounded MT Bold Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Rounded MT Bold Regular"),
    url("/fonts/Arial/ARLRDBD.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Narrow Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Narrow Italic"),
    url("/fonts/Arial/ARIALMTNARROWITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Regular";
  font-style: normal;
  font-weight: normal;
  src: local("ArialMT Regular"), url("/fonts/Arial/arialmt.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Black Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Black Regular"),
    url("/fonts/Arial/ARIALMTBLACK.woff") format("woff");
}

@font-face {
  font-family: "Arial Rounded MT Light";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Rounded MT Light"),
    url("/fonts/Arial/ARIALROUNDEDMTLIGHT.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Narrow Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Narrow Bold"),
    url("/fonts/Arial/ARIALMTNARROWBOLD.woff") format("woff");
}

@font-face {
  font-family: "Arial MT Narrow Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial MT Narrow Bold Italic"),
    url("/fonts/Arial/ARIALMTNARROWBOLDITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial Rounded MT ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Rounded MT ExtraBold"),
    url("/fonts/Arial/ARIALROUNDEDMTEXTRABOLD.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px !important;
  max-width: 1600px;
  margin: 0 auto;
  color: #21285d !important;
}
.query-input-wrap {
  font-weight: 400 !important;
}
.static-wrap-container {
  color: #21285d !important;
}
.aperto {
  font-family: "Arial" !important;
}

.ffHeader,
.searchFilters .sticky .sticky-inner {
  max-width: 1600px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.cookieConsent {
  max-width: 1600px;
  left: 50% !important;
  transform: translateX(-50%);
}

a {
  color: var(--primColor);
}

a:hover {
  color: #3f6b1f;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 12px;
  background: #2b2b2be0;
}

.brother-regular {
  font-family: "Arial" !important;
}

.customContainer {
  padding-left: 60px !important;
  padding-right: 60px !important;
  width: 100% !important;
}
.MuiButton-root {
  color: #21285d;
}
.MuiFormControl-root,
.MuiFormLabel-root,
.MuiTypography-root,
.MuiButton-label {
  font-family: "Arial" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.form-group legend span {
  padding-right: 14px !important;
}
.playFair {
  font-family: "Arial", sans-serif;
  letter-spacing: 1.5px;
}

button {
  outline: 0 !important;
}

.smallBtn .MuiButtonBase-root {
  height: 40px;
}

.primButton span.material-icons {
  padding-right: 10px;
}

.customTabs .MuiPaper-root.MuiAppBar-root {
  box-shadow: none;
  border: none;
  background: #fff;
}

.customTabs .MuiButtonBase-root.MuiTab-root {
  background: #d6f1c3;
  color: #21285d;
  margin-right: 10px;
  opacity: 1;
  border-radius: 15px;
  font-family: "Arial" !important;
  text-transform: capitalize;
}

.customTabs .MuiButtonBase-root.MuiTab-root.Mui-selected {
  background: #76bc43;
  color: #21285d;
  font-weight: 700;
}

.customTabs .PrivateTabIndicator-colorSecondary-6 {
  background-color: #309f68;
}

button,
div {
  outline: none;
}

.custom-modal {
  display: block;
}

.custom-modal .modal-dialog {
  width: 90%;
  max-width: 900px;
  margin-top: 2% !important;
}

.custom-modal .modal-header {
  background: #f0eff4;
  border-radius: 10px 10px 0 0;
  padding: 0.2rem 1rem !important;
  /* height: 60px; */
}

.custom-modal .modal-title {
  line-height: 1;
  font-family: "Arial";
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

.custom-modal .card {
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #eaeaea;
  max-width: 800px;
  margin: 10px auto;
}

.custom-modal .card-text,
.custom-modal .card-title {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #21285d;
}

.card-title .user_img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.swal2-popup.swal2-toast .swal2-title {
  font-weight: 400 !important;
}
.truck-turn .cp-label {
  color: #21285d;
}
.custom-modal .modal-body {
  max-height: calc(92vh - 25px);
  overflow: auto;
}

.sticky-wrapper {
  position: relative;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

.gray-btn.gray-btn-sm {
  width: 35px;
  margin-left: 5px;
  max-width: 35px;
  min-width: 35px;
}

.customTabs .MuiTabs-indicator {
  display: none;
}

.MuiFormHelperText-root {
  color: rgb(255 0 0) !important;
}

.helpingTooltip {
  font-size: 13px;
  color: #21285d;
}

.helpingTooltip span.material-icons {
  color: #737373;
  margin-right: 10px;
}

.ffRegInp .helpingTooltip {
  margin-top: -80px;
  float: right;
  width: max-content;
  cursor: pointer;
}

.exportBtn {
  background-color: #d6f1c3 !important;
  color: #21285d !important;
}

.noProductsFound {
  font-size: 18px;
  padding: 10px 0;
}

.exportBtn {
  background-color: #d6f1c3 !important;
  color: #21285d !important;
}

.addBank,
.addBank .address-card {
  width: 100%;
}

.savedAddr {
  margin-bottom: 20px;
}
.custom-modal .modal-content
{
  background-color: rgb(118 188 67 / 59%);
}
.static-wrap-container {
  padding: 100px 10px 20px;
  min-height: calc(100vh - 100px);
}

.reg-row {
  width: 100%;
  margin: 20px 0 0;
}

.cookieConsent {
  justify-content: center !important;
  align-items: center !important;
}

.cookieConsent > div {
  flex: auto !important;
}

.cookieConsent button {
  border-radius: 5px !important;
}

/* Responsive */

@media (max-width: 600px) {
  .noProductsFound {
    text-align: center !important;
    display: block;
    font-size: 14px;
  }
  .customTabs .MuiButtonBase-root.MuiTab-root {
    margin-right: 5px;
    opacity: 1;
    border-radius: 10px;
    min-height: 29px;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 500;
    background: #d7f1c3;
  }
  .customTabs .MuiTabScrollButton-root.MuiTabs-scrollButtons {
    height: 25px;
  }
}

@media (min-width: 768px) {
  .reg-row .col-md-4:first-child,
  .reg-row .col-md-4:last-child {
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .customContainer {
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: 100% !important;
    max-width: 100vw;
    box-sizing: border-box;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

@media (max-width: 767px) {
  .notify-table {
    max-width: calc(100vw - 70px);
  }
  .notify-table table {
    width: 767px;
  }

  body.swal2-toast-shown .swal2-container.swal2-center {
    width: 100%;
  }
}

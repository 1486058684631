.ffReg {
  background: url("../../assets/images/reg2.jpg");
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  padding: 60px 0;
  background-attachment: fixed;
}
.playFair{
  text-transform: none!important;
}

.ffReg.reg1 {
  background: url("../../assets/images/reg1.jpg");
  background-size: cover;
}
.ffRegInp .MuiTextField-root
{
  height: 64px !important;
  margin-bottom: 12px;
}
.ffReg .react-tel-input .form-control {    
border-radius: 0px;
margin-top: 15px;
border: none;
left: 0;
right: 0;
bottom: 0;
/* content: "\00a0"; */
position: absolute;
transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-bottom: 1px solid rgba(0, 0, 0, 0.42);
/* pointer-events: none; */
background: none;
}

.ffReg .react-tel-input {
  height: 78px !important;
  margin-top: -57px !important;
  
}
.ffReg .react-tel-input .selected-flag .flag
{
  margin-top: 5px;
}
.hear .MuiFormControl-root
{
  margin-bottom: 4px !important;
}
.reg2 .ffRegInp
{
  height: 92px;
}
.phony
{
  padding-top: 41px;
}
.reg2 .ffRegInp p
{
  height: 15px;
}
.ffRegInp p
{
  text-align: left;
}
.ffReg .flag-dropdown{
  border: 0px !important;
  background: none !important;
}
.herding .MuiInputLabel-formControl
{
  transform: translate(0, 0) scale(1);
}
.reg2 .ffRegTitle .helpingTooltip span.material-icons
{
  margin-top: 0px !important;
}
.reg2 .helpingTooltip span.material-icons
{
  margin-top: -15px !important;
}
.reg1 .ffRegCnt .ffRegInp .helpingTooltip span.material-icons
{
  margin-top: 17px;
}
.ffReg .ffRegCnt {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 30px 90px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 90%);
}
textarea {
  width: 381px;
  /* overflow: auto;
  resize: vertical; */
  height: 130px;
  font-size: 15px;
}

.ffReg .ffRegCnt .logo {
  font-size: 4em;
  margin: 0;
  font-weight: 500;
  color: var(--primColor);
}

.ffReg .ffRegCnt .greetMsg {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.ffReg .ffRegCnt .greetMsg h2 {
  font-size: 2.5em;
  font-weight: 700;
  color: var(--secColor);
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.ffReg .ffRegCnt .greetMsg p {
  font-size: 2em;
  font-weight: 400;
  color: var(--secColor);
  margin-top: 0;
  margin-bottom: 0;
}

.ffRegBox .ffRegInp {
  width: 48%;
  margin: 10px 0px;
}

.ffRegBox .ffRegInpFile {
  height: 16em;
  border: 1px dashed #c9c5d7;
  background: rgba(251, 251, 251, 70%);
  width: 100%;
  border-radius: 5px;
}

.ffRegBox .ffRegInpFile label {
  cursor: pointer;
  padding: 20px;
}

.ffRegBox .ffRegInpFile h4 {
  font-size: 1.6em;
  color: #737373;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.ffRegBox .ffRegInpFile p {
  font-size: 1.4em;
  color: #737373;
  margin: 0;
}

.ffReg .ffRegCnt .actBtn {
  margin: 0 auto;
  margin-top: 3.5em;
  width: 48em;
  max-width: 100%;
}

.ffReg .ffRegCnt .accHelp {
  text-align: center;
  margin-top: 3em;
}

.ffReg .ffRegCnt .accHelp p {
  font-size: 1.5em;
  font-weight: 400;
  color: var(--secColor);
  margin-bottom: 1em;
}

.ffReg .ffRegCnt .accHelp span {
  font-size: 1.5em;
  font-weight: 400;
  color: var(--secColor);
}
.ffReg .ffRegCnt .accHelp a {
  font-size: 1.5em;
  font-weight: 500;
  color: var(--primColor);
}

.ffReg .footerText {
  font-size: 1.6em;
  color: #f0eff4;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
}

.ffReg .ffRegBox .ffRegTitle {
  width: 100%;
}

.ffReg .ffRegBox .ffRegTitle h3 {
  color: var(--secColor);
  font-size: 1.8em;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
}

.ffReg .ffRegBox .addMoreHerd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.5em;
  font-weight: 500;
  color: var(--primColor);
  width: 100%;
}

.ffReg .ffRegBox .addMoreHerd span {
  padding-right: 5px;
}

.ffReg .regPayTitle {
  font-size: 1.8em;
  color: var(--secColor);
  margin: 60px -20px 30px 0;
  text-align: left;
  font-weight: 600;
}

.ffReg .regPayTitle span {
  font-weight: 300;
}

.MuiDialogActions-spacing .MuiSvgIcon-root {
  flex: 0 0 50%;
}

.ffReg .ffRegCnt .agreeTerms .MuiFormControlLabel-root {
  margin: 0 auto;
}

.d-flex.ffRegTitle .helpingTooltip {
  padding:0 10px;
}

.agreeTerms p {
  margin-bottom:0;
  text-align: left;
  color: #21285D;
}

@media (max-width: 767px) {
  .ffReg {
    padding: 0;
  }

  .ffReg .ffRegCnt {
    padding: 20px;
    border-radius: 0;
  }

  .ffRegBox .ffRegInp {
    position: relative;
  }

  .ffRegInp .helpingTooltip {
    margin-top: 20px;
    position: absolute;
    right: 0;
    top: -10px;
    padding:5px 0 10px;
  }

  .ffReg .footerText {
    color: #333;
    font-weight: 400;
    margin: 0;
    background: rgba(255, 255, 255, 90%);
    padding: 10px;
  }

  .MuiDialog-paperWidthSm {
    max-width: 600px;
    margin: 0;
    width: 90%;
  }

  .ffReg .ffRegCnt .greetMsg h2 {
    font-size: 2em;
  }
}

@media (max-width: 479px) {
  .ffRegBox .ffRegInp {
    width: 100%;
  }

  .ffReg .ffRegBox .ffRegTitle {
    width: 100%;
    flex-wrap: wrap;
  }

  .ffReg .ffRegBox .ffRegTitle h3 {
    width: 100%;
  }
}


body.swal2-toast-shown .swal2-container.swal2-center {
  z-index: 10000;
  font-weight: 400;
}
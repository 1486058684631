.searchFilters {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
  padding: 30px 25px;
}

.searchFilters .searchTitle {
  font-size: 2.4em;
  font-weight: 700;
  color: var(--primColor);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.searchFilters .saveSearch {
  font-size: 1.6em;
  color: var(--primColor);
  text-decoration: none;
}
.searchFilters .saveSearch span {
  padding-right: 10px;
}

.searchFilters .searchAuctions {
  margin-right: 60px;
}

.searchFilters .searchAuctions h4,
.searchFilters .searchLocations h4,
.searchFilters .filterOptions h4 {
  font-size: 1.6em;
  color: var(--secColor);
  margin-bottom: 10px;
  text-align: left;
  margin-top: 35px;
}

.searchFilters .filterOptions h4 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.searchFilters .searchAuctions label  {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--secColor);
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px dashed #cfcfcf;
  border-radius: 3px;
  margin-right: 10px;
  text-decoration: none;
  transition: 200ms all ease-in-out;
}

.searchFilters .searchAuctions label:hover  {
  border-color: var(--secColor);
}

.searchFilters .searchAuctions input:checked + label s {
  background: var(--secColor);
  color: #fff;
  border: 1px solid var(--secColor);
}

.searchFilters .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  z-index: 1;
  background: #fff;
  padding:0;
  font-size:12px;
}


.makeStyles-formControl-3 + .makeStyles-formControl-3 {
  margin-left:5px;
}

.searchLocations {
  min-width:200px;
}

.keySearch{
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keySearch .MuiFormControl-root.MuiTextField-root{
  margin: 0;
}

.filterOptions .keyBtn{
  color: #929292 !important;
  text-transform: initial !important;
  border-color:  #c4c4c4;
  font-size: 12px !important;
  min-width: 150px;
  height: 40px;
  margin-bottom: 5px;
}

.filterOptions .MuiFormControl-root{
  margin-right: 10px;
}

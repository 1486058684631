.ffHome .ffBanner {
  background: url("../../assets/images/FF_MB_Home.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ffHome .ffBanner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: transparent linear-gradient(180deg, #00000066 0%, #54545400 100%)
    0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.mobView .slick-track{
  flex-direction: column;
  height: 100% !important;
}
.mobView .slick-slide{
  min-height: auto;
}
.mobView .slick-list{
  height: auto !important;
  overflow: hidden;
  max-height: 585px;
}
.mobView .slick-slide.slick-active.slick-current > div, .customTabs .slick-slide.slick-active > div{
  width: 100%;
}

.ffHome .ffBanner .bannerContent {
  width: 100%;
  max-width: 900px;
  text-align: center;
  z-index: 1;
  padding: 10px;
  margin-bottom: 0;
}
.swalpop {
  z-index: 1052;
  font-weight: 400;
}
.ffHome .ffBanner .bannerContent button .MuiButton-label img {
  filter: brightness(10);
  width: 100%;
  display: block;
  margin-top: 10px;
}
.frontButton .MuiButton-containedPrimary {
  height: 155px;
}
.frontButton .MuiButton-label {
  display: block;
}
.frontButton .MuiButton-label .frontSpan {
  position: relative;
  bottom: 0px;
  font-family: "Arial";
}
.ffHome .ffBanner .bannerContent button:hover .MuiButton-label img {
  filter: none;
}

.ffHome .ffBanner .bannerContent h1 {
  color: #fff;
  font-size: 4.5em;
  line-height: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Arial";
}

.ffHome .ffBanner .bannerContent p {
  color: #f0eff4;
  font-size: 2.5em;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 1em;
  text-shadow: 0 0 8px #000;
}

.ffHome .ffBanner .bannerContent .primButton {
  min-width: 220px;
}

/* .ffHome .ffBanner .bannerContent .primButton:first-child {
  margin-right: 30px;
} */

.ffHome .referEarn .referContent {
  text-align: left;
  width: 100%;
  max-width: 500px;
}

.ffHome .referEarn .referContent h1 {
  font-size: 3.5em;
  font-weight: 700;
  color: #21285d;
  margin-bottom: 20px;
  text-transform: none;
  letter-spacing: 3px;
}
.ffHome .referEarn .referContent .playFair {
  text-align: left;
  font-family: "Arial";
}
.playFair.join {
  color: #76b643 !important;
}
.ffHome .referEarn {
  background: #eeffe2;
  padding: 60px 15%;
}
.bannerContent .farmfayre {
  margin-bottom: 15px;
  display: block;
}
.ffHome .referEarn object {
  width: 400px;
}

.ffHome .referEarn .referContent p {
  font-size: 1.5em;
  color: #21285d;
  margin-bottom: 10px;
}

.ffHome .referEarn .referContent .primButton {
  width: 275px;
}

.ffHome .ffBanner .bannerContent .searchBtn .primButton {
  width: max-content;
  margin: 0 auto;
  margin-top: 30px;
}

.ffHome .homeFoot .footer {
  margin-top: 3em;
}

.ffHome .customTabs .MuiBox-root.MuiBox-root-8 {
  padding: 0;
}

.ffHome .ffProducts {
  padding-top: 60px;
  padding-bottom: 60px;
}

/* .ffProducts .customTabs .slick-slide div {
  margin-right: 10px;
} */

.upcomingGrid .slick-slide
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 6px;
}
.allLiveSales .auctionCard
{
  margin-right: 10px;
}
/* .customTabs .allLiveSales .slick-slide.slick-active.slick-current>div
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
} */
.customTabs .slick-slide.slick-active.slick-current > div,.customTabs .slick-slide.slick-active > div {
  display: flex;
  justify-content: flex-start;
  margin-right: 3px;
}
/* .customTabs .slick-slide.slick-active,.customTabs .slick-track
{
  width: 100% !important;
} */
.customTabs .slick-track
{
  display: flex;
  overflow-x: scroll;
}
.customTabs .slick-track::-webkit-scrollbar
{
  display: none;
}
.customTabs .MuiTabScrollButton-root.MuiTabs-scrollButtons {
  color: #737171;
}

h3.allAuctionTab {
  text-align: left;
  font-size: 1.25rem;
}

.Wlpopup input {
  height: 50px;
  background: rgba(64, 73, 95, 80);
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #f0eff4;
}
.playStore {
  height: auto;
  padding: 55px 0px 30px;
}
.playStore .play-img {
  width: 150px;
  height: 50px;
  float: unset;
  padding-right: 0px !important;
  margin: 10px 37px;
}
.playStore .storeIcons .play-img:hover {
  box-shadow: rgb(72 191 132 / 20%) 0px 7px 29px 0px;
  border: 3px solid #76b643;
  border-radius: 12px;
}
.Wlpopup input::placeholder {
  font-size: 15px;
  color: #f0eff4;
}

.Wlpopup input:focus {
  color: #f0eff4;
}

.Wlpopup input:focus {
  background: rgba(64, 73, 95, 80);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .modal-content {
    border-radius: 1.3rem;
    background-color: rgba(118, 188, 67, 0.5);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid #76bc43;
  }
  .bannerContent .primButton button {
    background-color: rgba(118, 188, 67, 0.5);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid #76bc43;
  }
}
.bannerContent .primButton button:hover {
  color: #fff;
}

.Wlpopup button {
  height: 50px;
  background: var(--primColor);
  color: #21285d;
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: "Arial" !important;
  min-width: max-content;
}
.Wlpop p:nth-child(1) {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 10px;
}
.Wlpop p:nth-child(2) {
  font-size: 17px;
}
.Wlpop p {
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #fff;
}
.Wlpopup p {
  margin-bottom: 15px;
  font-size: 14px;
}
.Wlpopup {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
}
.playStore .playFair {
  color: #21285d;
}
.playStore h1.playFair {
  font-size: 2rem;
}
.newsCard big {
  color: #21285d;
}
.playStore h3.playFair {
  font-size: 1.5rem;
}
.storeIcons {
  margin: 15px auto 35px;
}

/* responsive */

@media (max-width: 767px) {
  .ffHome .ffBanner {
    height: auto;
    background-position: bottom;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 90px;
    min-height: 450px;
  }
  .bannerContent .farmfayre {
    margin-bottom: 0px;
    display: block;
  }
  .ffHome .referEarn .referContent .playFair {
    text-align: center;
  }
  .ffHome .referEarn {
    padding: 15px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .ffHome .referEarn img {
    max-width: 90%;
    width: 95px;
  }
  .referContent .primButton button {
    height: 43px;
  }
  .ffHome .ffBanner .bannerContent .primButton {
    width: 100%;
    max-width: 335px;
    margin: auto;
  }

  .ffHome .ffBanner:before {
    z-index: 99;
  }

  .ffHome .ffBanner .bannerContent {
    position: relative;
    z-index: 110;
    margin-bottom: 20px;
  }

  .ffHome .ffBanner .bannerContent h1 {
    font-size: 2rem;
    min-height: 90px;
    /* margin-bottom: 150px; */
  }

  .ffHome .ffBanner .bannerContent p {
    font-size: 1rem;
  }

  .ffHome .ffBanner .bannerContent p br {
    display: none;
  }

  .ffProducts .slick-next,
  .ffProducts .slick-prev {
    display: none !important;
  }

  .ffProducts .customTabs .slick-slide div > div {
    margin-right: 15px;
  }

  .auctionCard {
    max-width: initial !important;
    width: 100% !important;
  }

  .auctionCard .acImg {
    max-width: initial !important;
  }
}
@media (max-width: 600px) {
  .homeFoot .newsUpdates,
  .ffHome .playStore,
  .ffHome .referEarn {
    display: none !important;
  }
  .ffProducts .customTabs .slick-slide div > div {
    margin-right: 0;
  }
  /* h3.allAuctionTab {
    display: none;
  } */
  .ffHome .ffProducts .customTabs .MuiPaper-root {
    margin-bottom: 24px;
  }
  .ffHome .ffProducts .customTabs .MuiTabs-root {
    min-height: 25px;
  }
}
@media (max-width: 520px) {
  .playStore h1.playFair {
    font-size: 24px;
  }
  .newsCard p {
    margin-bottom: 0px !important;
  }
  .ffHome .ffProducts {
    padding-bottom: 10px;
    padding-top: 30px;
  }
  .newsUpdates {
    padding-bottom: 25px !important;
  }
  .newsCard {
    padding: 15px 10px;
  }
  .newsCard h1 {
    margin-bottom: 15px !important;
  }
  .playStore h3.playFair {
    font-size: 18px;
  }
  .playStore {
    padding: 35px 0px 30px;
  }
  .storeIcons {
    margin: 15px auto 20px;
  }
  .playFair.join {
    font-size: 16px;
  }
  .playStore .play-img {
    margin: 10px 12px;
  }
  .newsUpdates .nuTitle {
    font-size: 26px !important;
    padding-top: 35px !important;
    margin-bottom: 15px !important;
  }
}
@media (max-width: 480px) {
  .ffProducts .customTabs .slick-slide div > div {
    /* margin: 0 auto 20px; */
  }
    /* .ffHome .ffBanner .bannerContent h1

    {
      margin-bottom: 150px;
    } */
  .ffHome .ffBanner {
    max-height: 500px;
    min-height: 500px;
  }
}

@media (max-width: 360px) {
  .ffHome .ffBanner .bannerContent .primButton {
    max-width: 100%;
  }
}

.static-wrap-container {
  font-size: 15px;
  text-align: left;
}

.static-content {
  padding-top: 45px;
}

.static-content.p.big {
  float: left;
}
.big {
  float: left !important;
}

.static-content p {
  line-height: 1.5 !important;
  margin-bottom: 0;
}

.static-content h1 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  color: #21285D;
}

.static-content h2 {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #21285D;
  width: 100%;
  margin: 10px 0;
  line-height: 115% !important;
}
.static-content li
{
  color: #21285D;
}
.static-content h3 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #21285D;
  width: 100%;
  margin: 15px 0;
  line-height: 115% !important;
}

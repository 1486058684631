.ffSearch .searchBanner {
  background: url("../../assets/images/searchBg.jpg");
  height: 45vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ffSearch {
  margin-bottom: 5%;
  min-height: calc(100vh - 140px);
}
.playFair {
  text-align: center;
}
.ffSearch .searchOptions {
  width: 100%;
  margin: 0 auto;
  margin-top: -5%;
}

.ffSearch .searchTitle h2 {
  font-size: 2.4em;
  font-weight: 700;
  color: var(--secColor);
  margin: 0;
}
.searchGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 276px);
  grid-gap: 10px;
}
.filterDrawer .MuiDrawer-paper
{
  max-width: 300px;
  position: relative;
  z-index: 1297;
}
.filterDrawer
{
    z-index: 1297 !important;
}
.searchResults .filterCnt
{
  margin-bottom: 0px !important;
}
.filterDrawer .MuiDrawer-paper .filterDrawerClose
{
  position: absolute;
  top: 0;
  right: 5px;
}
.filterDrawer .MuiDrawer-paper .searchFilters
{
  box-shadow: none;
}
.ffSearch .searchTitle span {
  font-size: 1.5em;
  font-style: italic;
  color: #737373;
  padding-left: 25px;
  /* margin-bottom: 12px; */
}

.ffSearch .searchOptions .searchFilters {
  margin: 0 60px;
}

.ffSearch .searchResults {
  padding-top: 75px;
}

.ffSearch .searchResults .searchOptChildren {
  cursor: pointer;
  color: #737373;
}

.ffSearch .searchResults .searchOptChildren.active {
  color: var(--primColor);
}

.ffSearch .searchResults .searchOptChildren:not(last-child) {
  margin-right: 30px;
}

.ffSearch .searchGrid .auctionCard {
  margin-right: 10px;
  /* max-width:24%; */
  align-self: stretch;
}

.ffSearch .searchSorting button {
  text-transform: initial;
}

.viewAllLn {
  color: var(--primColor);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin: 0;
  margin-top: 30px;
}

.viewAllLn::before {
  height: 1px;
  width: 45%;
  position: absolute;
  content: "";
  top: 15px;
  left: 0;
  background: var(--primColor);
}
.viewAllLn::after {
  height: 1px;
  width: 45%;
  position: absolute;
  content: "";
  top: 15px;
  right: 0;
  background: var(--primColor);
}

.search-filter-link {
  width: 100%;
  height: 30px;
  max-width: 150px;
  display: none;
  margin: -15px auto 15px;
  background: #fff;
  border: 1px solid var(--primColor);
  color: var(--primColor);
  padding: 2px;
  font-size: 14px;
  border-radius: 15px;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .ffSearch .searchBanner {
    height: 25vh;
  }
  .ffSearch .searchOptions .searchFilters {
    margin: 0 10px;
    padding: 20px;
  }

  .filterOptions .d-flex {
    flex-wrap: wrap;
    margin: 0 -5px;
    justify-content: flex-start !important;
  }

  .filterOptions .MuiFormControl-root {
    margin: 0 5px;
    width: 132px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .lv-gray-box {
    flex-wrap: nowrap;
    height: 80px;
  }

  .rating-txt {
    position: absolute;
    left: -240px;
    top: 21px;
    width: 230px;
  }
}

@media (max-width: 767px) {
  .ffSearch .searchTitle span {
    display: block;
    padding-left: 0;
  }
  .searchTitle {
    margin: 10px 0;
  }
  .myDivs
  {
    flex-wrap: nowrap;
    width: 100%;
  }
  .ffSearch .searchBanner {
    display: none;
  }

  .ffSearch {
    padding-top: 90px;
  }
.searchList .media.auctionCard.listView .row.thisAucRow
{
  margin-top: 80px;
}
  .search-filter-wrapper {
    display: none;
  }

  .search-filter-link {
    display: block;
    position: fixed;
    bottom: 20px;
    background: #8cbc43;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    color: #fff;
  }
  .searchGrid {
    grid-template-columns: 1fr 1fr;
  }

  .ffSearch .searchResults {
    padding-top: 10px;
  }

  .ffSearch .searchTitle h2 {
    /* line-height: 22px;
    height: 22px; */
    padding: 4px 0;
    margin-right: 5px;
  }
  .ffSearch .searchResults .searchOptChildren:not(last-child) {
    margin-right: 10px;
  }

  .searchGrid.d-flex {
    margin: 0 -5px;
  }

  .ffSearch .searchGrid .auctionCard {
    margin: 0 5px 15px;
    padding: 5px;
    vertical-align: top;
    max-width: 160px;
  }

  .searchGrid .auctionCard .acImg {
    width: 100%;
    height: 200px;
  }

  .searchGrid .auctionCard .acTimer .pgTimer {
    font-size: 12px;
    padding: 5px 0 0;
    font-weight: 600;
  }

  .acPrice.d-flex > p,
  .auctionCard .acMisc p {
    width: 100%;
    line-height: 1;
    font-size: 12px;
  }
  .acPrice.d-flex > p + p,
  .auctionCard .acMisc > p + p {
    margin-top: 5px;
  }
  .searchList .auctionCard .acImg,
  .searchList .auctionCard .acImg img {
    max-width: 75px;
    height: 75px !important;
  }

  .searchList .media.auctionCard {
    position: relative;
  }

  .searchList .auctionCard .acImg {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .searchList .media.auctionCard .media-body {
    padding: 0;
  }

  .auctionCard.listView .listingHead {
    /* padding-left: 85px; */
    min-height: 75px;
  }
  .auctionCard.listView .listingHead .MuiButton-text {
    padding: 0;
    display: block;
  }

  .auctionCard .listTitle {
    font-size: 16px;
  }
  .listingHead .auction-num span {
    padding: 0;
    clear: both;
    display: block;
    opacity: 0;
    height: 1px;
  }
  .auctionCard.listView .acPrice {
    margin-bottom: 5px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .auctionCard.listView .lv-gray-box {
    height: auto;
  }
  .auctionCard.listView .ends-txt,
  .auctionCard.listView .ends-timer {
    display: flex;
    justify-content: space-between;
  }
  .auctionCard.listView .ends-txt p,
  .auctionCard.listView .ends-timer p {
    margin: 0;
  }

  .ffSearch .searchOptions .searchFilters {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .filterOptions .MuiFormControl-root {
    margin: 0 1% 10px;
    width: 48%;
  }

  .ffSearch .searchOptions.show-filter-xs {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    padding: 20px 15px;
    background: #fff;
    z-index: 999;
    overflow: auto;
    margin-top: 0;
  }

  .searchOptions.show-filter-xs .sticky-wrapper,
  .searchOptions .sticky .sticky-inner {
    position: relative;
    box-shadow: none;
  }

  .searchOptions .sticky .sticky-inner {
    padding: 10px 0;
  }

  .searchOptions .searchTitle {
    margin: 0;
  }
  .searchFilters .MuiButton-text {
    padding: 0;
  }
  .searchFilters .searchAuctions {
    margin-right: 0;
  }
  .searchFilters .searchAuctions h4,
  .searchFilters .searchLocations h4,
  .searchFilters .filterOptions h4 {
    margin-top: 15px;
  }

  .sclose-filter {
    position: fixed;
    width: 30px;
    height: 30px;
    padding: 1px;
    text-align: center;
    z-index: 99999;
    top: 20px;
    right: 20px;
    border: 1px solid #8cbc43;
    border-radius: 50%;
    background: #8cbc43;
    color: #fff;
    font-size: 16px;
  }

  .auctionCard.GridView .acPrice p,
  .auctionCard.GridView .acMisc p {
    width: max-content;
    margin: 5px 0;
  }

  .ffHome .referEarn .referContent {
    text-align: center;
  }

  .ffHome .referEarn .referContent h1 {
    margin-top: 10px;
    margin-bottom: 7px;
    font-size: 24px;
  }

  .ffHome .referEarn .referContent .primButton {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .searchGrid .auctionCard .acImg {
    height: 89px;
  }
  .filterCnt .MuiButton-root
  {
    min-width: 45px !important;
    padding: 5px;
  }
  .auctionCard.GridView .acPrice p,
  .auctionCard.GridView .acMisc p {
    font-size: 12px;
  }
  .searchGrid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 400px)
{
  .searchTitle
  {
    margin-right: 10px;
  }
}
@media (max-width: 360px) {
  .ffSearch .searchGrid .auctionCard {
    max-width: 100%;
  }
  .ffSearch .auctionCard .acImg {
    width: 100%;
    height: 150px;
  }
}

.ffLogin {
  background: url("../../assets/images/bannerBg.jpg");
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  padding: 60px 0;
  background-attachment: fixed;
  background-position:center;
}

.ffLogin.forgotPswd{
  background: url("../../assets/images/forgotPswd.jpg");
  padding: 120px 0;
}


.ffLogin .ffLoginCnt {
  width: 100%;
  max-width: 535px;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 90%);
}

.ffLogin .ffLoginCnt .logo {
  font-size: 4em;
  margin: 0;
  font-weight: 500;
  color: var(--primColor);
}

.ffLogin .ffLoginCnt .greetMsg {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.ffLogin .ffLoginCnt .greetMsg h2 {
  font-size: 2.5em;
  font-weight: 700;
  color: var(--secColor);
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.ffLogin .ffLoginCnt .greetMsg p {
  font-size: 2em;
  font-weight: 400;
  color: var(--secColor);
  margin-top: 0;
  margin-bottom: 0.8em;
}

.fpBox label {
  margin-bottom:0;
}

.fpBox .PrivateSwitchBase-root-4 {
    padding: 0 9px;
}

.ffLogin .ffLoginCnt .fpBox a {
  font-size: 1.6em;
  font-weight: 400;
  color: var(--secColor);
}

.ffLogin .ffLoginCnt .actBtn {
  margin-top: 3.5em;
}

.ffLogin .ffLoginCnt .accHelp {
  text-align: center;
  margin-top: 3em;
}

.ffLogin .ffLoginCnt .accHelp p {
  font-size: 1.5em;
  font-weight: 400;
  color: var(--secColor);
  margin-bottom: 1em;
}

.ffLogin .ffLoginCnt .accHelp span {
  font-size: 1.5em;
  font-weight: 400;
  color: var(--secColor);
}
.ffLogin .ffLoginCnt .accHelp a {
  font-size: 1.5em;
  font-weight: 500;
  color: var(--primColor);
}

.ffLogin .footerText {
  font-size: 1.6em;
  color: #f0eff4;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: 'Arial';
    text-shadow: 0 0 5px #000;
}
.regpopup p{
  margin-bottom: 0.3rem;
  font-size: 14px;
}
.cancelMe
{
  height: 60px;
  max-width: 85px;
  width: 100%;
  border-radius: 15px !important;
  margin-right: 10px !important;
}

@media(max-width:540px) {
  .ffLogin {
    padding: 0;
  }
  .ffLogin .footerText { 
    color: #333;
    font-weight: 400;
    margin: -1px 0 0;
    background: rgba(255, 255, 255, 90%);
    padding: 10px; 
    text-shadow:none; 
  }

  .ffLogin .ffLoginCnt {
    padding: 20px;
    border-radius: 0;
    min-height: calc(100vh - 44px);
  }
}
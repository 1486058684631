.contactUs {
  margin-top: 12%;
  margin-bottom: 45px;
}

.contactUs .contactTitle {
  font-family: 'Arial';
  max-width: 800px;
  margin: 0 auto;
}

.logoContactUs {
  margin-bottom: 35px;
}

.contactUs .contactAddr {
  font-size: 1.5em;
}

.contactUs .contactForm {
  margin-top: 20px;
}

.contactUs .contactForm h3 {
  color: var(--primColor);
  font-family: "Arial";
}

.contactUs .contactForm .primButton {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .contactUs .contactTitle {
    font-size: 1.5rem;
  }
  .contactUs {
    margin-top: 150px;
    margin-bottom: 45px;
  }

  .contactUs .contactForm {
    margin-top: 15px;
  }

  .contactForm object {
    height: 240px;
    margin-top: -20px;
  }
}

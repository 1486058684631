.share-link {
  width: 100%;
  /* height: 200px; */
  border-radius: 20px;
  /* background: #f3f2f2;
  padding: 1.25rem 2rem; */
  margin: 20px auto;
}

.refer-head {
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.25rem;
  margin-bottom: 1.5rem;
  color: #21285d;
}
.refer_earn {
  max-width: 368px;
  width: 100%;
  height: 220px;
}
.share-link ul,
.wallet-balance ul {
  color: #21285d;
}
.share-link ul {
  margin-bottom: 8px;
}
.share-link ul .howList {
  margin: 5px 0px;
}
.how_it_works {
  margin-bottom: 8px;
}
.referrals {
  font-size: 15.6px;
  color: #21285d;
  margin-bottom: 0px;
}
.refer-descp {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: left;
  color: #21285d;
  max-width: 900px;
}

.share-link .media-right img {
  margin-top: 20px;
}
.media {
  align-items: center;
}
.wallet-balance {
  max-width: 1200px;
  padding: 30px 0 0;
}

.wallet-head {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #21285d;
  margin-bottom: 1.25rem;
  font-weight: 600;
  display: flex;
}
.wallet-head span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.wallet-head b {
  margin-left: 10px;
}

.wallet-head b {
  font-weight: 600;
}

.refer-link {
  position: relative;
  max-width: 868px;
}

.refer-link .referral {
  border-radius: 3px;
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  height: 44px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  line-height: 20px;
  color: #737373;
  padding: 10px 15px;
}

.refer-link .material-icons {
  display: inline-block;
  vertical-align: middle;
  color: var(--primColor);
  float: right;
  top: 10px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  background: #fff;
}

.ref-txt {
  font-weight: 300;
  font-size: 0.975rem;
  line-height: 1.25rem;
  text-align: left;
  color: #21285d;
  margin: 1.25rem 0;
}

.ref-his-head {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #21285d;
  font-weight: 700;
  display: flex;
  margin: 2rem 0 1rem;
}

.email-invite-btn {
  background: #76bc43;
  min-height: 38px;
  color: #21285d;
}

.wallet-balance ul,
.share-link ul {
  font-size: 0.975rem;
}

.wallet-balance ul li {
  margin-bottom: 7px;
}

.emailInvite {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
}

.emailInvite form {
  width: 90%;
}

.emailInvite button {
  width: 10%;
}

@media (max-width: 767px) {
  .share-link {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column-reverse;
    padding: 20px;
  }

  .share-link .media-right img {
    max-width: 100%;
  }

  .refer-head {
    margin: 15px 0;
    line-height: 1.67;
    font-size: 1.5rem;
  }
}

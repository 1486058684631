.product-wrapper {
  padding: 30px 15px 30px;
  max-width: 1140px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 60px;
}
.biddingMessages {
  margin-bottom: 10px;
}
.biddingMessages.lost {
  background: #ffe1e1;
  height: 60px;
  width: 100%;
}
.auction-num1 {
  font-size: 1rem;
  color: #737373;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.auction-num1 .favoriteCheck {
  padding-right: 15px;
}

.biddingMessages.lost h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: red;
  /* text-transform: uppercase; */
}
.biddingMessages.winning {
  background: #e0fbe2;
  height: 60px;
  width: 100%;
}
.modal-dialog {
  max-width: 500px;
  margin: 14.75rem auto !important;
}
.custom-modal .modal-dialog {
  width: 90%;
  max-width: 569px !important;
}
.biddingMessages.winning h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #00910e;
  /* text-transform: uppercase; */
}
.biddingMessages.won {
  background: #ffecd9;
  height: 60px;
  width: 100%;
}

.biddingMessages.won h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #ff8801;
  /* text-transform: uppercase; */
}

.pcon-head {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #737373;
  margin-bottom: 0.25rem;
  /* font-family: 'Arial'; */
}

.timerHead {
  display: flex;
  width: 100%;
  background: #dddbe3;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 100px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.timerHead span.material-icons {
  margin-right: 5px;
}
.timerHead p {
  margin-right: 10px;
  margin-bottom: 0;
}
.pcon-txt {
  line-height: 1.2rem;
}
.pcon-txt a {
  line-break: anywhere;
}
.pcon-txt,
.pcd-label {
  font-size: 16px;
  line-height: 1.75rem;
  color: #21285d;
  font-family: "Arial";
}

.light-red {
  color: #ff9761 !important;
}

.pcon-descp {
  font-weight: 300;
  font-size: 1rem;
  text-align: left;
  color: #21285d;
  font-family: "Arial";
}

.pcd-label span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.pcd-label {
  margin-bottom: 1rem;
}

.bid-history-head {
  font-family: "Arial";
  font-weight: bold;
  font-size: 1.125rem;
  letter-spacing: 0.02em;
  text-align: center;
  color: #21285d;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.bh-con-box {
  width: 100%;
  height: 45px;
  background: #f3f2f8;
  padding: 5px 40px;
  font-weight: normal;
  font-size: 1rem;
  display: flex !important;
  color: #727272;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  position: relative;
}

.bid-history-wrapper .bh-con-box:first-child::before {
  position: absolute;
  content: "\f521";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  top: 12px;
  left: 10px;
  font-size: 15px;
  color: #ff9800;
}

.review-link {
  display: inline-block;
  vertical-align: middle;
  margin: 2px 10px;
  line-height: 1;
  color: var(--primColor);
  cursor: pointer;
}

.current-bid {
  height: 70px;
  border-radius: 15px;
  background: #fff;
  border: 1px dashed #ff9761;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ff9761;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  font-family: "Arial";
  word-break: break-all;
}

.current-bid span {
  display: block;
  width: 100%;
  text-align: center;
}

.current-bid.resPriceMet {
  color: var(--primColor);
  border: 1px dashed var(--primColor);
}

.current-bid .resPrice {
  width: 100%;
  color: #777777;
}

.query-input-wrap .MuiFormControl-marginNormal {
  margin-top: 0;
}

.pbid-wrap .MuiFormControl-marginNormal {
  margin-top: 0;
}

.pbid-wrap .MuiOutlinedInput-adornedStart {
  padding-left: 0;
  border-radius: 15px;
}

.pbid-wrap .MuiInputAdornment-positionStart {
  background: #f8f8f8;
  color: #727272;
  min-width: 38px;
  height: 56px;
  text-align: center;
  max-height: 56px;
  justify-content: center;
  font-size: 16px;
}

.pbid-wrap .material-icons {
  font-size: 21px;
}

.em-bid {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: var(--primColor);
  text-transform: uppercase;
  margin: 15px auto;
  line-height: 1.5rem;
  cursor: pointer;
}

.em-bid .material-icons {
  display: inline-block;
  vertical-align: middle;
  margin: -5px 5px 0;
}

.or {
  position: relative;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.or span {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  background: #fff;
}

.or:before,
.or:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #ddd;
  width: 42%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1px;
}

.or:before {
  left: 0;
}
.or:after {
  right: 0;
}

.light-grn-btn {
  width: 100%;
  height: 60px;
  border-radius: 15px;
  background: #d6f1c3;
  border: 1px solid #d6f1c3;
  font-weight: 500;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primColor);
  text-transform: uppercase;
  color: #21285d;
}

.light-grn-btn:hover,
.light-grn-btn:focus {
  background: #fff;
  color: var(--primColor);
  border-color: var(--primColor);
}

.item-id {
  font-size: 1rem;
  color: #737373;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0px;
}

.pvItemInfo {
  margin-bottom: 15px;
}

.pvItemInfo button {
  color: var(--primColor);
  font-size: 1rem;
}

.pvItemInfo button .material-icons {
  padding-right: 5px;
}

.item-title {
  font-family: "Arial";
  font-weight: bold;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #21285d;
  margin: 12px 0 10px;
}

.qn-head {
  font-family: "Arial";
  font-weight: bold;
  line-height: 1.1rem;
  font-size: 1.1rem;
  color: #21285d;
  margin: 1.1rem 0;
}

.query-wrapper {
  height: 400px;
  background: #f0eefb;
  border-radius: 10px;
  padding: 0 0 60px;
  position: relative;
  margin-bottom: 20px;
  overflow: auto;
  padding: 20px;
}

.query-content {
  height: calc(400px - 60px);
  overflow: auto;
  padding: 15px;
}

.query-input-wrap {
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  height: 60px;
}

.query-input-wrap .MuiInputBase-root.MuiInput-root {
  padding: 0;
}

.query-input-wrap input {
  height: 60px;
  border-radius: 0 0 10px 10px;
  padding: 0;
  padding-left: 15px;
}

.query-input-wrap .blue-btn {
  width: 127px;
  height: 60px;
  border-radius: 0px 6px 6px 0px;
  background: #21285d;
  border: 1px solid #21285d;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.query-input-wrap .blue-btn:hover {
  color: #21285d;
  background-color: #fff;
}

.msg-wrap {
  width: 100%;
  max-width: 740px;
  clear: both;
  margin-bottom: 10px;
}

.msg-con {
  max-width: calc(100% - 125px);
  height: auto;
  border-radius: 0px 35px 35px 35px;
  background: #d6f1c3;
  align-items: center;
  padding: 10px 15px;
  font-size: 0.875rem;
  color: #fff;
  display: inline-block;
  color: #21285d;
}

.msgUserInfo {
  float: left;
  margin-right: 15px;
}

.msg-date {
  display: inline-block;
  width: auto;
  text-align: right;
}

.msg-wrap.cu-msg {
  float: right;
  width: auto;
}

.msg-wrap.cu-msg .msg-con {
  border-radius: 25px;
  background: #fff;
  color: #4a556e;
  min-width: 60px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  padding: 10px;
  position: relative;
  max-width: 335px;
}

.msg-wrap.cu-msg .msg-date {
  text-align: left;
}

.msg-user {
  margin: 10px 0;
  font-size: 0.875rem;
  text-align: left;
  display: block;
  margin-right: 15px;
  margin: 0;
}

.msg-user .material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-left: 0;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 312px;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image video {
  max-height: 312px;
}

.image-gallery-content.left.fullscreen
  .image-gallery-slide
  .image-gallery-image {
  max-height: 100vh;
  height: 100%;
}

.image-gallery-content.left.fullscreen .image-gallery-slides {
  background: initial;
}

.image-gallery-icon {
  width: 30px;
  height: 30px;
  color: #21285d;
  padding: 0;
  filter: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ebebeb;
  padding: 5px;
}

.image-gallery-left-nav {
  left: 10px;
}

.image-gallery-right-nav {
  right: 10px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 75px;
  line-height: 0;
  height: 52px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 5px;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  width: 75px;
  border: none;
}

.image-gallery-thumbnail:hover .image-gallery-thumbnail-image,
.image-gallery-thumbnail:focus .image-gallery-thumbnail-image {
  border: 2px solid #76bc43;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  width: 75px;
}

.total-items {
  display: flex;
  height: 55px;
  border-radius: 5px;
  background: #fff;
  border: 2px dashed #cfcfcf;
  max-width: 612px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  color: #21285d;
}

.total-items .light-grn-btn {
  width: 115px;
  height: 35px;
  border-radius: 3px;
  cursor: pointer !important;
  text-decoration: none !important;
}
.video {
  max-height: 420px;
  height: 100%;
}
.card-text {
  color: #fff;
}
.card-text .text-danger {
  color: #ff5c5c;
}
.total-items .light-grn-btn:hover,
.total-items .light-grn-btn:focus {
  cursor: pointer !important;
  text-decoration: none !important;
}

.one-click {
  font-size: 12px;
  line-height: 21px;
  text-align: left;
  color: #737373;
  padding: 5px;
}

.one-click .material-icons {
  display: inline-block;
  vertical-align: middle;
  color: #76bc43;
  font-size: 21px;
  float: right;
  cursor: help;
}

.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
  width: 100%;
  max-width: 685px;
  padding: 0;
  overflow: initial !important;
}

.MuiDrawer-paperAnchorRight
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  max-height: 325px;
  object-fit: contain;
  width: 100%;
  border-radius: 5px;
}

.MuiDrawer-paperAnchorRight
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  text-align: left;
  margin-bottom: 25px;
}

.mi-item-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #737373;
}

.mi-title {
  font-family: "Arial";
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #21285d;
}
.MuiDrawer-paper .mi-title {
  margin-left: 30px;
}
.drawer-close {
  width: 40px;
  height: 40px;
  color: #21285d;
  position: absolute;
  box-sizing: border-box;
  display: block;
  font-size: 25px;
  border-radius: 50%;
  line-height: 1;
  top: 30px;
  left: 0px;
  background: #e4e7ef;
  text-align: center;
  padding: 7px;
  cursor: pointer;
}

.MuiDrawer-paperAnchorRight > div {
  height: 100vh;
  padding: 30px;
  overflow-y: auto;
}

.prev-item,
.next-item {
  width: 200px;
  height: 50px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #76bc43;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #76bc43;
  padding: 10px;
  line-height: 1.67;
  margin: 0 10px 10px;
}

.prev-item:hover,
.next-item:hover {
  background: #76bc43;
  border: 1px solid #76bc43;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.prev-item .material-icons,
.next-item .material-icons {
  display: inline-block;
  vertical-align: middle;
}

.prev-item .material-icons {
  margin-right: 10px;
}

.next-item .material-icons {
  margin-left: 10px;
}

.sort-by {
  text-align: right;
  max-width: 800px;
  margin: 10px auto;
}

.sort-by label,
.sort-by select {
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: 15px;
  color: #737373;
  margin: 0 5px;
  outline: none !important;
}

.sort-by select {
  border: none;
}

.max-bid-container,
.pover-wrap {
  padding: 15px 10px 5px;
  max-width: 500px;
  width: 100%;
}

.pover-wrap {
  margin: 0;
  padding: 20px;
  font-size: 15px;
  max-width: 500px;
  width: 100%;
}

.pover-wrap a {
  color: var(--primColor);
  font-weight: 600;
}

.product-wrapper .image-gallery-thumbnails button {
  transition: 200ms all linear;
}

.product-wrapper .image-gallery-slides {
  background: #f6f6f6;
}

.product-wrapper
  .image-gallery-icon.image-gallery-fullscreen-button
  .image-gallery-svg:hover {
  color: var(--primColor);
}

.product-wrapper .image-gallery-icon.image-gallery-fullscreen-button {
  right: 10px;
  bottom: 10px;
}

.auctionNotStarted {
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-family: "Arial";
  color: #b8b8b8;
}

.mi-btns-wrap {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.mi-btns-wrap button {
  margin: 0 0.5%;
  width: 49%;
  display: inline-block;
  max-width: 225px;
}

.bid-history-wrapper {
  max-height: 340px;
  overflow: hidden;
  overflow-y: auto;
}

.pvSellerMisc {
  height: 20px;
}
.watermark
{
  position: absolute;
  top: 3px;
  right: 32px;
  z-index: 3;
}
.pcon-txt
{
  white-space: nowrap;
}
.feedLotMess .MuiCheckbox-root
{
  padding-top: 0px;
}
.feedLotMess label
{
  margin-right: 5px;
}
.watermark img
{
  max-width: 55px;
  width: 100%;
}
.feedLotMess
{
  display: flex;
  align-items: start;
  font-weight: 600 !important;
}
@media (max-width: 992px) {
  .timerHead {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .MuiDrawer-paperAnchorRight > div {
    padding: 15px;
  }
  .allDetails .form-group:nth-child(even) .pcon-head,
  .allDetails .form-group:nth-child(even) .pcon-txt,
  .sideProduct .form-group:nth-child(even) .pcon-head,
  .sideProduct .form-group:nth-child(even) .pcon-txt,
  .allDetails .pcd-label.localLabel {
    text-align: right;
  }
  .pvSellerMisc {
    justify-content: end;
  }
  .prev-item .material-icons {
    margin-right: 5px;
  }

  .timerHead {
    font-size: 14px;
  }

  .product-wrapper > .container {
    padding: 0;
  }

  .product-wrapper {
    padding: 0 15px;
  }

  .current-bid {
    height: 40px;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .current-bid {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .pbid-wrap .MuiOutlinedInput-input {
    padding: 5px 14px;
  }

  .current-bid,
  .pbid-wrap .MuiInputAdornment-positionStart,
  .pmb-btn button {
    min-height: 60px;
    height: auto;
  }

  .total-items {
    font-size: 12px;
  }
  .total-items .light-grn-btn {
    width: 80px;
    font-size: 12px;
  }

  .msg-con {
    max-width: 90%;
  }
}
@media (max-width:600px)
{
  .watermark
  {
    right: 11px;
  }
    .watermark img
    {
      max-width: 50px;
    }
}
@media only screen and (max-width: 480px) and (orientation: landscape) {
  .video {
    max-height: 270px;
    height: 100%;
  }
}
@media only screen and (max-width: 845px) and (orientation: portrait) {
  .video {
    max-height: 270px;
    height: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .video {
    max-height: 255px;
    height: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .MuiButtonBase-root.ffWatchVideo {
    color: var(--primColor);
    font-size: 1rem;
    text-align: center;
    width: 100%;
    padding: 15px 0;
  }
}
@media only screen and (max-width: 500px) {
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left;
  }

  .current-bid {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .pvSellerMisc {
    height: unset;
  }
}
@media only screen and (max-width: 360px) {
  .mi-btns-wrap button {
    width: 100%;
    max-width: 100%;
    margin: 10px 0 0;
  }
}

.auctionCard {
  max-width: 300px;
  width: 100%;
  /* margin: 0px 5px; */
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  /* margin: 15px 8px; */
  background: #fff;
  min-width: min-content;
  color: #21285d;
}

.auctionCard .acImg {
  width: 100%;
  /* max-width: 280px; */
  height: 210px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
}

.auctionCard .acImg > button,
.auctionCard .acImg > button > span {
  height: 100%;
  padding: 0;
  width: 100%;
}

.auctionCard.GridView > .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  padding: 0;
}

.auctionCard.GridView
  > .MuiButtonBase-root.MuiButton-root.MuiButton-text
  .MuiButton-label {
  text-transform: initial;
  display: block;
}

.auctionCard.GridView .acImg {
  overflow: hidden;
}

.auctionCard.GridView .acImg img {
  transition: 700ms all ease-in-out;
}

.auctionCard.GridView .acImg img:hover {
  transform: scale(1.1);
}

.no-items {
  position: absolute;
  width: auto;
  height: 30px;
  border-radius: 15px;
  min-width: 90px;
  bottom: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  font-size: 15px;
  text-align: center;
}

.auctionCard .acImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.searchList .auctionCard .acImg {
  max-width: 230px;
}

.searchList .auctionCard .acImg img {
  width: 100%;
}

.auctionCard .acTimer {
  margin: 12px 0;
}

.auctionCard .acTimer span {
  padding-right: 5px;
}

.auctionCard .acTimer .pgTimer {
  font-size: 14px;
}

.auctionCard .acTimer p {
  font-size: 13px;
  color: #21285d;
  margin: 0;
  padding-right: 5px;
}

.auctionCard .acTitle h1 {
  font-size: 18px;
  color: #21285d;
  margin-bottom: 5px;
  font-family: "Arial";
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-transform: initial;
  width: 100%;
}

.auctionCard .acTitle button {
  width: 100%;
  padding: 0;
}

.auctionCard .acTitle p {
  font-size: 13px;
  color: #21285d;
  margin-bottom: 12px;
  font-weight: 500;
  text-align: left;
}

.auctionCard .acPrice {
  /* margin-bottom: 12px; */
  min-height: 45px;
}
.auctionCard .acPrice p:first-child
{
  margin-right: 5px;
}
.auctionCard .acPrice p {
  font-size: 15px;
  color: #21285d;
  margin: 0;
  text-align: left;
}

.auctionCard .acPrice span {
  font-size: 14px;
  color: var(--primColor);
  font-weight: 500;
  padding-left: 10px;
}

.auctionCard.GridView {
  font-family: "Arial";
}

.auctionCard.GridView .acTimer {
  font-size: 12px;
}

.auctionCard.listView .acPrice {
  margin-bottom: 12px;
  text-align: right;
}

.auctionCard.listView .acPrice p {
  font-size: 15px;
  color: #21285d;
  margin: 0;
  text-align: left;
}

.auctionCard.listView .listingHead {
  width: 80%;
}

.auctionCard.listView .lv-descp {
  margin-top: 0;
}

.auctionCard.listView .acPrice span {
  font-size: 24px;
  color: var(--primColor);
  font-weight: 600;
  padding-left: 10px;
}

.auctionCard .acMisc p {
  font-size: 14px;
  color: #21285d;
  margin: 0;
  text-align: left;
  display: flex;
  align-items: center;
}
.auctionCard .acMisc span {
  font-size: 18px;
  color: #21285d;
  padding-right: 10px;
  text-align: left;
}

.cc-descp {
  font-size: 15px;
  text-align: left;
}

.media.auctionCard {
  max-width: 100%;
  text-align: left;
}

.media.auctionCard .media-body {
  padding: 0 20px;
}

.fs-20 {
  font-size: 20px;
}

.auction-num {
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  color: #737373;
  font-family: "Arial" !important;
}

.auction-num span {
  padding: 0 10px;
}

.sp-pcon-head {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #737373;
}

.pcon-txt1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  color: #21285d;
  font-family: "Arial" !important;
}

.lv-descp {
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #737373;
  margin: 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.media-left.acImg {
  position: relative;
}

.media-left.acImg .favoriteCheck {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}

.auctionCard .media-left.acImg img {
  height: 230px;
}

.auctionCard .media-body .form-group {
  margin: 1rem 0 0;
}

.auctionCard .listTitle {
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.lv-gray-box {
  width: 100%;
  padding: 10px;
  display: flex;
  height: 201px;
  border-radius: 0px 5px 5px 0px;
  background: #faf6fe;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.lv-gray-box > * {
  width: 100%;
}

.ends-txt,
.ends-timer {
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #737373;
  margin: 5px 0;
}

.ends-txt {
  font-family: "Arial" !important;
}

.lv-gray-box .MuiButton-contained {
  max-height: 40px;
}

.rating-txt {
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 1.67;
  text-align: center;
  color: #21285d;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0;
}

.rating-txt .material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

@media screen and (max-width: 1200px) {
  .auctionCard .acTimer {
    flex-wrap: wrap;
  }
  .searchGrid .auctionCard .acTimer {
    flex-wrap: nowrap;
  }
  .auctionCard .acTimer .pgTimer {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .auctionCard .acTimer p {
    display: none;
  }
  .auctionCard .acTimer .pgTimer {
    font-size: 12px;
    letter-spacing: -1px;
    color: red;
  }
  .auctionCard .acPrice p {
    font-size: 12px;
    font-weight: 400;
  }
  .auctionCard .acTimer {
    margin: 5px 0;
  }
  .auctionCard {
    box-shadow: 0px 0px 6px rgb(0 0 0 / 11%);
    /* margin: 3px; */
  }
  .favoriteCheck label span {
    font-size: 14px;
  }
  .no-items {
    height: 17px;
    min-width: 52px;
    bottom: 6px;
    right: 6px;
    padding: 1px;
    font-size: 10px;
    background-color: #00000057;
  }
  .auctionCard .acPrice span {
    padding-left: 5px;
  }
  .auctionCard .acMisc span {
    font-size: 16px;
    padding-right: 3px;
  }
  .auctionCard .acTitle h1 {
    -webkit-line-clamp: 2;
    font-size: 13px;
    font-weight: 500;
  }
  .auctionCard.GridView {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .auctionCard.GridView .acImg {
    margin-right: 0px !important;
    height: 89px;
    border-radius: 6px !important;
  }
  .auctionCard.GridView .mobileView {
    margin-right: 0 !important;
  }
}
@media (max-width:700px){
  .auctionCard .acPrice
  {
    min-height: 25px;
  }
}
@media screen and (max-width: 425px) {
  .auctionCard.GridView {
    gap: 6px;
  }
}

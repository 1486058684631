.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiPickersBasePicker-pickerView {
  max-width: unset !important;
}
.MuiDialogActions-root {
  justify-content: end !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.MuiInputBase-input,.MuiTypography-body1,.MuiListItemText-primary
{
  color: #21285d !important;
}
input[type="text"],
input[type="password"],
input[type="number"],
select:focus,
textarea {
  font-family: 'Arial';
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiFormLabel-root
{
  letter-spacing: 0.04938em !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.newsCard .thHead,
.newsCard .tbBody
{
  justify-content: center;
}
.App-link {
  color: #61dafb;
}
.MuiFormHelperText-root,.MuiSelect-selectMenu,.MuiSelect-outlined.MuiSelect-outlined,.MuiSelect-select.MuiSelect-select,.MuiMenuItem-root,.react-tel-input .form-control,.MuiOutlinedInput-inputMultiline,.MuiOutlinedInput-input,.MuiInputBase-inputMultiline,.MuiInputBase-input
{
  font-family: 'Arial' !important;
}
.swal2-popup.swal2-toast .swal2-title,.swal2-popup.swal2-toast .swal2-icon::before
{
  margin-top: 10px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cstmTblWrp .resp-td.cntxtWrp {
  width: 100%;
  min-width: 200px;
}

.cstmTblWrp {
  font-size: 0.85rem;
}

.header-main {
  background-color: #fff !important;
  max-width: 1600px;
  width: 100%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.site-content {
  padding: 8em 0 1em !important;
}
/* .cstmTblWrp .resp-td.cntxtWrp .dtWrper{
  max-width: 200px;
} */
.table td {
  color: #21285d;
}
.noProductsFound {
  color: #21285d;
}
.cstmTblWrp .cstmWrpCls {
  white-space: pre-wrap;
  margin-top: 5px;
  min-height: 60px;
  font-size: 0.8rem;
  color: #707070;
}

.cstmTblWrp .nwWrp {
  white-space: pre;
}

.cstmTblWrp .stsWrpr.stsWrpr {
  font-size: 0.88rem;
}

.cstmTblWrp .resp-td.ltNbrWrp {
  width: 150px;
}
input[type="text"],
input[type="number"],
select:focus,
textarea {
  font-size: 16px;
}
.modal-content p {
  font-size: 14px;
}
.table td {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ff-resp-table,
  .ff-resp-table tr,
  .ff-resp-table td,
  .ff-resp-table tbody {
    display: revert;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
/* @media screen and (max-width: 1405px) {
  .table tbody td a.complaint
  {
    margin-top: 5px;
  }
} */
@media only screen and (max-width: 80em) {
  .site-content {
    padding: 8em 0 1em !important;
  }
}

.checkout-container {
  margin-bottom: -6.5rem;
  padding: 120px 15px;
  text-align: left;
  min-height: calc(100vh - 290px);
}
.checkout-container.nohdr {
  padding-top: 40px;
  margin-bottom: 0;
}

.cp-breadcrumb {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #737373;
  margin-bottom: 1rem;
}
.delivery
{
  transform: translateY(15px);
  display: inline-block;
  color: #21285D;
}
.cp-breadcrumb .material-icons {
  display: inline-block;
  vertical-align: middle;
  background: #e2e0e8;
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 6px;
  border-radius: 50%;
  margin-right: 10px;
}

.cc-img {
  width: 206px;
  height: 183px;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
  margin-right: 15px;
}

.cp-item-id {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #737373;
}

.cp-title {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #21285D;
  margin-top: 5px;
}

.ac-radio .material-icons {
  top: 1.5rem !important;
}
.amt-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  color: #21285D;
  margin-top: 15px;
}

.price-txt {
  float: right;
  display: inline-block;
  vertical-align: middle;
}

.MuiFormGroup-root.fd-row {
  flex-direction: row;
}

.cp-label {
  font-size: 15px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  margin: 0 30px 0 0;
  padding: 9px 9px 9px 0;
}

.card.checkout-card {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #fff;
  margin-bottom: 25px;
}
.mandate-acceptance
{
  color: #21285D;
}
.shred-radio-wrapper {
  line-height: 4;
}
.card.checkout-card .form-row label
{
  color: #21285D;
}
.shred-custom-card {
  position: relative;
  min-width: 130px;
  width: auto;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2%;
}

.shred-custom-card + .shred-custom-card {
}

.shred-custom-card input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
  top: 0;
  left: 0;
}

.shred-custom-card .sc-label {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  background: #fff;
  border: 1px dashed #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: normal;
  font-size: 15px;
  color: #21285D;
}

.shred-custom-card input[type="radio"]:checked ~ .sc-label {
  background: #21285D;
  color: #fff;
  border: 1px solid #21285D;
}

.edd-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #21285D;
}

.edd-label span.material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.cc-title {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 18px;
  color: #21285D;
}

.cc-title a {
  display: inline-block;
  vertical-align: middle;
  float: right;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  color: #76bc43 !important;
}

.cc-title a span.material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.card_Form
{
  margin-bottom: 20px;
}
.card_Form form
{
  margin-bottom: 15px;
}
.ac-radio h6.ch-name {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0 20px;
}

.pd-label {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #788094;
}

.address-card.pc-radio-wrap .ac-radio {
  height: 105px;
  padding: 10px 15px;
}

.address-card.pc-radio-wrap .ac-radio .row {
  margin: 0 -5px;
  margin-top: 45px;
}

.address-card.pc-radio-wrap .ac-radio .col-6 {
  padding: 0 5px;
}

.use-diff {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #76bc43;
  margin: 20px auto;
  text-transform: uppercase;
  cursor: pointer;
}

.use-diff span.material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
/* .price-txt .wallet{
    margin-left: 10px;
} */
.price-wallet {
  float: right;
  display: inline-block;
  vertical-align: middle;
  margin-left: -141px;
}
.card .media-body .amt-label.helpingTooltip .material-icons {
  padding-left: 10px;
  cursor: pointer;
}

.card .media-body .amt-label.helpingTooltip a {
  cursor: pointer;
}

@media (max-width: 767px) {
  .checkout-container .container {
    padding: 0;
  }

  .checkout-container .media {
    flex-wrap: wrap;
  }

  .checkout-container .media-left {
    margin-bottom: 10px;
    width: 100%;
  }

  .cc-title a {
    float: none;
    margin: 0 -10px;
  }
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

.form-group {
  font-size: 14px !important;
}

input,
.StripeElement {
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  margin-bottom: 15px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
@media (max-width:425px){
  .delivery
{
  transform: translateY(0px);
  display: inline-block;
}
}
@media (max-width:375px){
  .delivery
{
  transform: translateY(1px);
  display: inline-block;
}
}


.seller-note p,
.sdec-note li,
.seller-note table td {
  font-size: 14px;
  text-align: left;
  margin-bottom: 12px;
}

.seller-note table td {
  margin-bottom: 0;
  padding: 5px 10px;
}

.pl-btns {
  height: 60px;
  max-width: 200px;
  margin: 0 auto;
}

.jc-center .MuiFormControlLabel-root {
  align-items: start !important;
}
.jc-center .MuiTypography-root {
  text-align: left;
}
.jc-center .MuiFormGroup-row {
  justify-content: center;
}


.catlog-filters .searchTitle {
  font-size: 2.4em;
  font-weight: 700;
  color: var(--primColor);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.catlog-filters .saveSearch {
  font-size: 1.6em;
  color: var(--primColor);
  text-decoration: none;
}
.catlog-filters .saveSearch span {
  padding-right: 10px;
}

.catlog-filters .searchAuctions {
  margin-right: 60px;
}

.catlog-filters .searchAuctions h4,
.catlog-filters .searchLocations h4,
.catlog-filters .filterOptions h4 {
  font-size: 1.6em;
  color: var(--secColor);
  margin-bottom: 10px;
  text-align: left;
  margin-top: 35px;
}

.catlog-filters .filterOptions h4 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.sticky .filterOptions h4 {
  display:none;
}

.catlog-filters .searchAuctions label,
.catlog-filters .searchLocations label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--secColor);
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px dashed #cfcfcf;
  border-radius: 3px;
  margin-right: 10px;
  text-decoration: none;
  transition: 200ms all ease-in-out;
}

.catlog-filters .searchAuctions label:hover,
.catlog-filters .searchLocations label:hover {
  border-color: var(--secColor);
}

.catlog-filters .searchAuctions input:checked + label,
.catlog-filters .searchLocations input:checked + label {
  background: var(--secColor);
  color: #fff;
  border: 1px solid var(--secColor);
}

.catlog-filters .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  z-index: 1;
  background: #fff;
  padding:0;
  font-size:13px;
}

.catlouge-wrapper .container {
  max-width:1280px;
  width:100%;
}
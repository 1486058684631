.customDropdown {
  position: relative;
}
.customDropdown .dropdownLabel {
  font-size: 15px;
  color: var(--secColor);
  text-decoration: none;
  padding: 5px;
  border-radius: 3px;
}

.customDropdown .dropdownLabel span {
  padding-left: 10px;
}

.customDropdown .dropdownLabel:focus {
  /* background: #f2f2f2; */
}

.customDropdown .dropdownOptions {
  padding: 0;
  list-style: none;
  text-align: left;
  transition: 150ms background linear;
  position: absolute;
  top: 35px;
  width: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  box-shadow: 0px 3px 6px #e6e6e6;
  min-width: 170px;
  right: 0;
}

.customDropdown .dropdownOptions li:not(last-child) {
  border-bottom: 1px solid #eaeaeacc;
}

.customDropdown .dropdownOptions li:hover {
  background: #f0f0f0;
}

.customDropdown .dropdownOptions li a {
  padding: 10px;
  font-size: 14px;
  color: var(--secColor);
  text-decoration: none;
  display: block;
}

.fs-card{
    max-width: 300px;
    width: 100%;
    padding:10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #fff;
    -ms-flex-align:center;
    align-items:center;
    max-height: 89px;
    height: 100%;
}

.fs-card .acImg {
    width:60px;
    height:60px;
    border-radius:50%;
    margin-right:15px;
}

.fs-card .acImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:center;
    border-radius:50%;
}
 
.fs-card .acTitle h1{
    font-size:16px;
    color: #21285D;
    margin-bottom: 12px;
    font-weight: 700;
    text-align: left;
    word-break: break-word;
}
 
@media(max-width:767px) {
    .fs-card {
        max-width:100%;
    }
}
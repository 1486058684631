.pull_right {
  position: absolute;
  right: 5%;
}

.popup-body {
  padding: 0 !important;
  border: 1px solid red;
}

.popup-content {
  background-color: transparent !important;
  border: none !important;
}
.custom-modal .modal-content {
  color: #fff;
  font-weight: 600;
}
.custom-modal .modal-header {
  text-align: center;
  background-color: transparent;
  border-bottom: none;
  margin: auto;
}
.custom-modal .modal-header button {
  position: absolute;
  right: 29px;
  top: 7px;
}
.custom-modal .modal-header .modal-title {
  text-align: center;
}
.popup-dialog {
  margin: 14.75rem auto !important;
}
.custom-modal .popup-dialog {
  width: 100%;
}

.custom-modal.newsPopupHome {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal.newsPopupHome .popup-dialog {
  margin: 4.75rem auto !important;
}

.custom-modal.newsPopupHome .modal-content {
  width: max-content;
  margin: auto;
}

.custom-modal.newsPopupHome .modal-content .news_image img {
  width: auto !important;
  max-width: 100% !important;
  height: 55vh !important;
}

.custom-modal.newsPopupHome .popup-dialog p {
  margin: 0;
}

.custom-modal.newsPopupHome .pull_right {
  position: absolute;
  right: 4% !important;
  top: 2% !important;
}

.modal-lg {
  width: calc(90% - (90% * 0.33)) !important;
}

@media screen and (max-width: 991px) {
  .modal-lg {
    max-width: 500px;
  }
  .pull_right {
    position: absolute;
    right: 12%;
  }
}

@media screen and (max-width: 500px) {
  .modal-lg {
    width: calc(90% - (90% * 0.1)) !important;
  }
}

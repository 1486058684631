.recentAuctions {
  position: relative;
}
.recentAuctions .acPrice span {
  color: var(--secColor);
}

.recentAuctions .acTitle h1 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.recentAuctions .soldTo {
  color: var(--primColor);
  margin-bottom: 12px;
  font-size: 16px;
}

.recentAuctions .soldTo i {
  padding-right: 5px;
}

.recentAuctions .soldTime {
  font-size: 12px;
  color: #847f99;
  text-align: left;
  margin-bottom: 0;
}

.recentAuctions .soldIcon {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
 .recentAuctions .soldIcon svg {
   width: 100%;
   height: 100%;
   object-fit: contain;
   opacity: 0.8;
 }
 .recentAuctions .soldIcon {
   width: 130px;
   bottom: -7px;
   right: 86px;
   left: auto;
 }
@media (max-width: 600px) {
  .recentAuctions .soldIcon {
    width:80px;
    bottom: 11px;
      right: 86px;
      left: auto;
  }
  .recentAuctions .soldIcon svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.8;
  }
  .auctionCard.recentAuctions {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px;
  }
  .auctionCard.recentAuctions .acImg {
    margin-right: 0px !important;
    height: 89px;
    border-radius: 10px !important;
  }
  .auctionCard.recentAuctions .mobileView {
    margin-right: 0 !important;
  }
  .auctionCard.recentAuctions .acImg img {
    border-radius: 10px !important;
  }
  .auctionCard.recentAuctions .acTitle h1 {
    margin-top: 0;
  }
  .auctionCard.recentAuctions .acPrice,
  .auctionCard.recentAuctions .acTitle p {
    margin-bottom: 5px;
  }
}

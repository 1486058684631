.newsCard {
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 25px 10px;
  text-align: left;
  width: 300px;
  border-bottom: 5px solid var(--primColor);
  width: 100%;
  height: 100%;
}

.newsCard h1 {
  font-size: 2em;
  font-family: "Arial MT Bold", "Arial";
  line-height: 20px;
  letter-spacing: initial;
  margin-bottom: 25px;
  color: var(--secColor);
}
.newsCard .slick-slide h2{
  display: none;
}
.newsCard .slick-slide:first-child{
  display: none !important;
}
.newsCard .slick-slide:first-child p{
  display: none;
}
.newsCard .slick-slide p{
  margin-bottom: 15px;
}
.newsCard.updates .slick-list{
  height: 230px !important;
}
.newsCard p {
  font-size: 1.5em;
  line-height: 18px;
  margin-bottom: 30px;
  color: #847f99;
}

.newsCard .newsActBtn {
  font-size: 2em;
  color: var(--primColor);
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
}

.newsCard .newsActBtn span {
  padding-left: 5px;
}

.newsUpdates .nuTitle {
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 40px;
  padding-top: 60px;
  color: #fff;
}

.newsUpdates {
  background: #21285D;
  position: relative;
  height: 550px;
  padding-bottom: 45px;
}

.newsUpdates .subsSection {
  width: 35%;
}

.newsUpdates .news-wrapper {
  flex: 1;
  margin: 0;
  padding-right: 15px;
  width: calc(100% - 60%);
  padding: 0 15px;
}

.newsUpdates .slick-slide {
  margin-right: 15px !important;
}

.newsUpdates .slick-arrow.slick-prev {
  top: -15%;
  left: inherit;
  right: 30px;
  position: absolute;
  transform: translate(-40%, -100%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--primColor);
}

.newsUpdates .slick-arrow.slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 14px;
  line-height: 2;
}

.newsUpdates .slick-arrow.slick-next {
  top: -15%;
  right: 2%;
  position: absolute;
  transform: translate(-50%, -100%);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--primColor);
}

.newsUpdates .slick-arrow.slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 14px;
  line-height: 2;
}

.subsSection h2,
.subsSection h3 {
  font-size: 1.8em;
  color: #fff;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-style: italic;
  text-align: center;
}
.subsSection h3 {
  margin-bottom: 20px;
  font-size: 2em;
  font-style: normal;
}
.subsSection input {
  height: 50px;
  background: rgba(64, 73, 95, 80);
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #f0eff4;
}

.subsSection input::placeholder {
  font-size: 15px;
  color: #f0eff4;
}

.subsSection input:focus {
  color: #f0eff4;
}

.subsSection input:focus {
  background: rgba(64, 73, 95, 80);
}

.subsSection button {
  height: 50px;
  background: var(--primColor);
  color: #21285d;
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: "Arial" !important;
  min-width: max-content;
}

.subsSection p {
  font-size: 13px;
  color: #cfcfcf;
  margin-top: 5px;
  text-align: left;
}

.newsCard .table thead th {
  border-bottom: 2px solid #dee2e6;
  background: #fff;
  color: #21285D;
  font-weight: 500;
  font-size: 12px;
}

.newsCard .table tbody th,
.newsCard .table tbody td {
  font-size: 12px;
}

.newsCard h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.newsCard table {
  margin-bottom: 0;
}

.newsCard .thHead,
.newsCard .tbBody {
  width: 100%;
  text-align: left;
  display: flex !important;
}

.newsCard .thHead {
  font-size: 12px;
  font-weight: 500;
  font-family: "Arial";
}

.newsCard .tbBody {
  font-size: 12px;
}
.newsCard .thHead > div,
.newsCard .tbBody > div {
  width: calc(100% / 5);
  display: inline-block;
  word-break: break-word;
  background: #f5f5f5;
  border: 1px solid #fff;
  padding: 5px;
  color: #21285d;
}

.newsCard .tbBody > div a {
  font-size: 12px;
  color: var(--primColor);
}

.newsCard .slick-list {
  height: 180px !important;
}

.newsCard .slick-track {
  top: 20px;
}

@media screen and (max-width: 1200px) {
  .newsUpdates .slick-arrow.slick-prev {
    transform: translate(-100%, -100%);
  }
}

@media screen and (max-width: 1200px) {
  .subsSection button {
    min-width: 130px;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .newsUpdates .subsSection {
    width: 40%;
    max-width: 40%;
  }

  .newsUpdates .news-wrapper {
    width: 60%;
    max-width: 60%;
  }
}

@media (max-width: 767px) {
  .newsUpdates {
    height: auto;
  }

  .d-flex {
    flex-wrap: wrap;
  }
  .newsUpdates .subsSection,
  .newsUpdates .news-wrapper {
    width: 100%;
    padding: 5px 0 0;
    max-width: 100%;
  }

  .newsUpdates .slick-arrow.slick-prev,
  .newsUpdates .slick-arrow.slick-next {
    top: 0;
  }
}

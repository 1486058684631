.trustapRedirect {
    text-align: center;
  }
  
  .trustapRedirect h2 {
    margin-top: 40px;
    margin-bottom: 45px;
    font-weight: 800;
    line-height: 45px;
    color: var(--secColor);
  }
  
  .trustapRedirect p, .trustapRedirect h6 {
    font-size: 18px;
  }
  
  
  .trustapRedirect .tr_logo {
    margin-top: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .trustapRedirect .register
  {
  font-size: 35px;
  font-weight: bold;
  }
  .trustapRedirect .tr_logo img {
      margin-bottom: 10px;
    }
  
  .trustapRedirect .tr_logo img:first-child {
    margin-right: 30px;
  }
  
  @media (max-width: 600px) {
    .trustapRedirect h2 {
      line-height: 30px;
      font-size: 20px;
      margin-bottom: 25px;
    }
    .trustapRedirect p, .trustapRedirect h6 {
      font-size: 15px;
    }
    
  }
  
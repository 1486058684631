
.favoriteCheck { 
    position: relative;
}
  
  .favoriteCheck input {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
  }
  
  .favoriteCheck label {
    color:#FF643C;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top:0;
    /* background:rgba(209,209,209,0.5); */
    width:34px;
    height:34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .favoriteCheck label:hover {
    color: grey;
  }
   
  
  .favoriteCheck input:checked + label {
    color: #FF643C;
  }
  

  .acImg .favoriteCheck {
    position:absolute;
    top:0;
    right:0;
    z-index: 10;
  }
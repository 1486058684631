
.vcInvoice {
  padding: 2rem !important;
  margin:20px auto;
  text-align:left;
  font-size:14px;
}
.vcInvoice .invLogo {
  width: 150px;
}

.db-cheader1 a {
  float: right !important;
  padding-right:147px!important;
  color: #a29bba !important;
    text-transform: capitalize!important;
    font-size: 1rem!important;
    cursor: pointer!important;
  
}
.db-cheader1 a span {
  width: 2.25rem;
  height: 2.25rem;
  background: #e2e0e8;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  padding: 0.4rem;
  margin-right: 1rem;
}

.vcInvoice .right{
  text-align: right !important;
}
.vcInvTot span{
  font-weight: 500;
}

.invoiceCard {
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}

.card-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6f2;
}

.invoiceCard h3 {
  font-size: 20px;
}
.invoiceTitle {
  font-size: 18px;
  margin: 35px 0 15px 0;
  font-weight: 600;
  color: #3a3a3a;
}
.vcInvoice .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(241, 241, 241);
}
.vcInvoice .invoiceCard .table thead th {
  font-weight: 500;
  font-size: 15px;
}
.vcInvoice .invoiceCard .table tbody td {
  font-weight: 400;
  font-size: 15px;
}
.invoiceCard h5 {
  font-size: 15px;
  line-height: 26px;
  color: #3d405c;
  margin: 0px 0px 15px 0px;
}

.text-dark {
  color: #3d405c !important;
}
.transDet .invDiv {
  /* width: 25%; */
  margin-bottom: 15px;
}
.transDet p {
  font-size: 15px;
  margin-bottom: 0px;
  color: #5f5f5f;
}
.transDet span {
  width: 100%;
  font-weight: 500;
}


.invoice-head {
  font-size:32px;
  font-family: 'Arial';
}

.iwd-100 {
  width:100%;
}

.db-cheader1.invoice-top { 
  display:flex;
  justify-content:space-between;
  width:100%;
  max-width:1076px;
  margin:125px auto 0;
}

.db-cheader1.invoice-top a {
  padding-right:0 !important;
  float:none !important;
  /* margin: auto; */
}
@media screen and (max-width:1100px) {
  .db-cheader1.invoice-top a {
    margin: 0px 20px;
  }
}
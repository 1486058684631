.dashboard-wrapper {
  margin-top: 6.6rem;
  display: flex;
  min-height: calc(100vh - 240px);
}

.dashboard-menu {
  width: 16.75rem;
  background: #f0eff4;
}

.user-img {
  width: 17em;
  margin: 6em auto 3em;
}
.user-img img {
  width: 152px;
  height: 152px;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}
.user-name {
  font-family: "Arial";
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
  color: #21285d;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #fff;
}

.db-menu {
  width: 17.5rem;
  list-style: none;
  padding: 0;
  margin: 1.25rem 0 2.5rem 1.25rem;
}

.db-menu li a {
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: left;
  color: #737373;
  width: 100%;
  display: block;
  padding: 1.25rem 1.5rem;
  margin-bottom: 0.3em;
  height: 3.75rem;
}

.db-menu li a span.material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.25rem;
}

.db-menu li a:hover,
.db-menu li a:focus,
.db-menu li a.active {
  background: #fff;
  border-radius: 1.5rem 0 0 1.5rem;
  text-decoration: none;
}

.dashboard-content {
  flex: 1;
  padding: 3rem;
  text-align: left;
}

.db-cheader {
  font-weight: 500;
  font-size: 1.5rem;
  /* text-transform: uppercase; */
  color: var(--secColor);
  line-height: 2.125rem;
  font-weight: 600;
}

.db-cheader a {
  float: right;
  color: #a29bba !important;
  text-transform: capitalize;
  font-size: 1rem;
  cursor: pointer;
}

.db-cheader a span {
  width: 2.25rem;
  height: 2.25rem;
  background: #e2e0e8;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  padding: 0.4rem;
  margin-right: 1rem;
}

.custom-switch {
  padding-right: 0;
  padding-left: 0;
}

.custom-switch .custom-control-input {
  left: inherit;
  right: 0;
  width: 34px;
  height: 14px;
}

.custom-switch .custom-control-label {
  width: 100%;
}

.custom-switch .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  background: #b4b4b4;
  border-color: #b4b4b4;
  right: 0;
  left: inherit;
  width: 34px;
  height: 14px;
  opacity: 0.5;
}

.custom-switch .custom-control-label::before,
.custom-control-input ~ .custom-control-label::before {
  background: #cfcfcf;
  border-color: #cfcfcf;
  right: 0;
  left: inherit;
  width: 34px;
  height: 14px;
  opacity: 0.5;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(118, 186, 65, 0.5);
  border-color: rgba(118, 186, 65, 0.5);
}

.custom-switch .custom-control-label::after {
  background: #cfcfcf;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  right: 22px;
  left: inherit;
  top: 1px;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #76bc43;
  right: 0;
}

.pw-box {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #efefef;
  padding: 2.25rem;
  margin: 2.25rem auto;
}

.customTabs .MuiAppBar-colorDefault {
  background: #fff;
  box-shadow: none;
}

.customTabs .MuiTab-textColorInherit {
  color: var(--primColor);
  opacity: 1;
  background: #e4faef;
  margin-right: 10px;
}

.customTabs .MuiBox-root {
  padding: 0;
  margin: 25px auto 0;
}

.customReviewBox {
  margin: 0 !important;
}
.customReview {
  height: auto !important;
}
.customReviewLabel {
  padding-top: 13px;
}

.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid #343a40;
  background: #343a40;
  color: #fff;
  white-space: nowrap;
  font-weight: bold;
  font-family: "Arial" !important;
}

.gray-btn {
  background: #f0eff4;
  border: 1px solid #f0eff4;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  color: #21285d;
  min-width: 100px;
  height: 35px;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
}

.gray-btn:hover,
.gray-btn:focus {
  border: 1px solid #21285d;
  background: #fff;
  color: #21285d;
}

.table td,
.table th {
  vertical-align: top;
  padding: 0.5rem;
  font-size: 1rem;
}

.table td {
  color: #21285d;
}

.green-txt {
  color: #76bc43 !important;
}

.red-txt {
  color: #fa674f !important;
}

.warning-txt {
  color: #ac8400 !important;
}

.ppic-wrap {
  position: relative;
  width: 152px;
  height: 152px;
  margin: 0 auto 50px;
}

.ppic-wrap .pp-img {
  width: 152px;
  height: 152px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.productPostThumbnails {
  margin-top: 10px;
}

.productPostThumbnails .pro-img {
  height: 110px;
  width: 100%;
  object-fit: contain;
}

.pp-edit {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: var(--primColor);
  color: #fff;
  border: 1px solid var(--primColor);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: block;
  position: absolute;
  text-align: center;
  padding: 5px;
  right: 19px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.productPostThumbnails .pp-edit {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: #fa6055;
  border: none;
  color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: block;
  position: absolute;
  text-align: center;
  padding: 5px;
  right: 20px;
  top: 5px;
  bottom: initial;
  margin: auto;
  font-size: 15px;
  cursor: pointer;
  z-index: 10;
}

.label-txt {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #21285d;
  margin-bottom: 2rem;
}

.notify-check .MuiFormControlLabel-root {
  margin: 0.5rem auto;
}

.notify-check .MuiIconButton-root {
  padding: 0;
}

.light-grn-btn {
  width: 160px;
  height: 35px;
  border-radius: 3px;
  background: #e4faef;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: var(--primColor);
  padding: 5px;
  line-height: 24px;
  border: 1px solid var(--primColor);
}

.light-grn-btn:hover,
.light-grn-btn:focus {
  background: var(--primColor);
  color: #fff;
}

.light-grn-btn.disabled {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.26);
}

.light-grn-btn.disabled:hover,
.light-grn-btn.disabled:focus {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.26);
}

.light-grn-btn .material-icons {
  display: inline-block;
  vertical-align: middle;
}

.table thead th.wd-100,
.cd-input {
  width: 100px;
}

.cd-input1 {
  width: 145px;
}

.table thead th.wd-175 {
  width: 185px;
}

.table tbody td .gray-btn {
  vertical-align: top;
  font-size: 14px;
}

.table tbody td a.gray-btn {
  min-width: 40px;
  margin-left: 5px;
  width: 100%;
  max-width: 170px;
}
.table tbody td a.complaint {
  max-width: 130px;
}
.table tbody td a.submitComplaint {
  max-width: 140px;
}
.dp-img {
  width: 115px;
  height: 105px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}
.rtc-buttons.bids {
  /* display: flex; */
  /* flex-direction: column; */
}
/* .rtc-buttons.bids .complaint,.rtc-buttons.bids .submitComplaint
{
  margin-top: 5px;
} */
.hdb-media {
  display: flex;
  flex-wrap: wrap;
}

.hdb-media .hdb-media-left {
  width: 115px;
}

.hdb-media .hdb-media-body {
  flex: 1;
  padding: 0 20px;
}

.dp-con {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  color: #21285d;
  padding: 8px 0;
}

.dp-con-head {
  font-family: "Arial" !important;
  font-size: 18px;
  font-weight: 600;
  padding: 25px 0 10px;
}

.dp-link {
  font-family: "Arial" !important;
  font-weight: 500;
  font-size: 16px;
  color: #76bc43;
}

.di-details {
  display: flex;
  margin: 0 -10px;
  justify-content: space-between;
}

.di-details > div {
  max-width: 25%;
  padding: 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  text-align: left;
  color: #21285d;
}

.di-title-con {
  display: flex;
  justify-content: space-between;
}

.di-title-con > div:first-child {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #21285d;
}

.cd-input .MuiInputAdornment-positionEnd {
  height: 38px;
  padding: 0;
  min-height: 38px;
  width: 30px;
}

.cd-input .MuiTypography-colorTextSecondary {
  color: #727272;
  font-weight: bold;
}
.referNEarn span
{
  font-size: 23px;
  font-weight: 600;
  margin-right: 29px;
  margin-left: 6px;
}
.referNEarning span,.headerOdd a span {
  font-size: 21px;
  font-weight: 600;
  margin-right: 16px;
  color: #636363;
  margin-left: 6px;
}
.headerOdd a span
{
  margin-left: 3px;
  margin-right: 10px;
}
.headerOdd
{
  margin-bottom: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.referNEarn a
{
  display: flex !important;
  align-items: center;
}
.hdb-media-body .custom-switch {
  padding-right: 0;
  padding-left: 0;
}

.cd-input1 .MuiSelect-outlined.MuiSelect-outlined {
  padding: 10px 32px 10px 10px;
}

.upload-wrapper {
  position: relative;
  margin: 20px auto;
}

.upload-wrapper input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100px;
  opacity: 0;
  top: 0;
  left: 0;
}

.upload-wrapper input[type="file"] ~ label {
  padding: 20px;
  text-align: center;
  border: 2px dashed #cfcfcf;
  border-radius: 5px;
  width: 100%;
  display: block;
}

.confirm-btn-wrap {
  width: 200px;
  margin: 30px auto;
  display: block;
}

.herd-number {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 16px;
}

.herd-number a {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  color: #76bc43;
}

.herd-number a span.material-icons {
  display: inline-block;
  vertical-align: middle;
}

.db-cheader a.menu-mobile {
  display: none;
}

.table td.pd-zero {
  padding: 0;
}

.notifDash {
  position: relative;
}

.notifDash .notifCount {
  width: 22px;
  height: 23px;
  background: #76bc43;
  border-radius: 100%;
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
}
@media (max-width: 1366px) {
  .bidsWontable {
    max-width: 950px;
  }
}
@media (max-width: 1280px) {
  .bidsWontable {
    max-width: 860px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .dashboard-wrapper {
    margin-top: 102px;
  }

  .dashboard-menu {
    width: 200px;
    background: #f0eff4;
  }

  .db-menu {
    width: calc(100% - 1rem);
    margin: 1rem 0 2.5rem 1rem;
  }

  .db-menu li a {
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    padding: 1rem;
    margin-bottom: 0.3em;
    height: 3.2rem;
  }

  .dashboard-content {
    padding: 20px;
    max-width: calc(100vw - 200px);
  }

  .pw-box {
    padding: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1530px) {
  .rtc-buttons.bids {
    display: flex;
  }
  .table tbody td a.gray-btn {
    width: fit-content;
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper {
    margin-top: 80px;
    flex-wrap: wrap;
  }
  .dashboard-wrapper {
    display: block;
    min-height: 100%;
  }
  .db-bids-wrapper {
    padding: 15px;
  }

  .db-bids-wrapper .row {
    margin: 0 -5px;
  }

  .db-bids-wrapper .col-sm-4 {
    padding: 0 5px;
  }

  .dashboard-content {
    padding: 20px 15px;
    flex: 1 1 auto;
  }

  .dashboard-menu {
    width: 100%;
    background: #fff;
    height: 100%;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    z-index: 99;
    margin: 0;
    padding: 0;
    overflow: auto;
    display: block;
    /* max-height: 100vh; */
    max-width: 275px;
    display: none;
    overflow-x: hidden;
  }

  .MuiDrawer-paperAnchorLeft .dashboard-menu {
    display: block;
  }

  .db-cheader a.menu-mobile {
    display: inline-block;
    border: 1px solid #76bc43;
    color: #fff !important;
    background: #76bc43;
    height: 30px;
    padding: 5px 15px;
    box-sizing: border-box;
    line-height: 1.35;
    vertical-align: middle;
    margin: 5px;
    font-size: 14px;
    border-radius: 2px;
  }

  .pw-box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .cstmTblWrp .resp-td.ltNbrWrp,
  .cstmTblWrp .resp-td.cntxtWrp,
  .cstmTblWrp .resp-td.cntxtWrp .dtWrper {
    max-width: 100%;
    width: 100%;
  }

  .ff-resp-table,
  .ff-resp-table tr,
  .ff-resp-table td,
  .ff-resp-table tbody {
    display: block;
  }

  .ff-resp-table td.resp-td {
    display: flex;
    border: none;
    justify-content: space-between;
    font-size: 13px;
  }

  .ff-resp-table td.resp-td:before,
  .ff-resp-table td.resp-td:after {
    display: table;
  }

  .ff-resp-table td.resp-td:before {
    content: attr(data-label);
    width: 45%;
  }

  .ff-resp-table td.resp-td:after {
    clear: both;
  }

  .ff-resp-table td.resp-td .rtc-wrap {
    flex: 1;
    word-break: break-word;
    white-space: break-spaces;
    float: right;
    /* max-width: 72%; */
    text-align: right;
  }
  .ff-resp-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .show-menu-xs .black-trans {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .db-cheader {
    font-size: 1rem;
  }

  .db-cheader a span {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 5px;
    font-size: 18px;
  }

  .dashboard-wrapper .MuiTabs-scroller {
    white-space: normal;
    flex-wrap: wrap;
  }

  .dashboard-wrapper .MuiTabs-scroller > .MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dashboard-wrapper .customTabs .MuiButtonBase-root.MuiTab-root {
    width: 48%;
    margin: 0 0 15px;
  }

  .mobile-hide {
    display: none !important;
  }

  .close-mobile {
    width: 35px;
    height: 35px;
    position: fixed;
    left: 260px;
    top: 100px;
    background: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 50%;
    z-index: 99999;
    text-align: center;
    text-decoration: none;
  }
}
@media (max-width: 667px) {
  .rtc-buttons .complaint,
  .rtc-buttons .submitComplaint {
    margin-top: 5px;
  }
}
@media (max-width: 600px) {
  .customTabs .MuiBox-root {
    margin: 0;
  }
}
@media (max-width: 525px) {
  .rtc-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .rtc-buttons .line {
    display: none;
  }
  .rtc-buttons .complaint,
  .rtc-buttons .submitComplaint {
    margin-top: 5px;
  }
}
@media (max-width: 460px) {
  .gray-btn {
    height: auto;
  }
}

.errorPages {
  text-align: center;
  margin-top: 15%;
  margin-bottom: 5%;
}

.errorPages .error404 {
  width:90%;
  max-width:400px;
  margin: 20px auto;
}

.errorPages h1 {
  font-family: "Arial";
}

.errorPages p {
  font-size: 20px;
}
.errorPages .primButton {
  width: 200px;
  margin: 20px auto;
}


@media(max-width:767px) {
	.errorPages {
		margin-top:90px;
	}

	.errorPages h1 {
		font-size:24px;
	}
}
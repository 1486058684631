.ffHeader {
  padding-top: 15px;
  padding-bottom: 15px;
  position: absolute;
  top: 0;
  /* left: 0; */
  background: rgba(254, 254, 254, 85%);
  z-index: 100;
  border-bottom: 1px solid #ececec;
}

.ffHeader .ffLogoHead h1 {
  font-size: 2.5em;
  font-weight: 500;
  color: #fff;
}

.ffHeader .ffnavLinks a {
  font-size: 1.6em;
  margin-right: 3em;
  color: var(--secColor);
  transition: 300ms all ease-in-out;
  position: relative;
  font-family: "Arial";
}

.ffHeader .ffnavLinks a.active {
  color: var(--primColor);
}
.main_pop .MuiMenu-paper {
  top: 85px !important;
}
.ffHeader .ffnavLinks a:hover {
  color: var(--primColor);
  text-decoration: none;
}

.ffHeader .ffnavLinks a.noLink:hover {
  color: var(--secColor);
  text-decoration: none;
  cursor: initial;
}

.ffHeader .ffnavLinks a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -10px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--primColor);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.ffHeader .ffnavLinks a.noLink:after {
  content: none;
}

.ffHeader .ffnavLinks a:hover:after {
  width: 100%;
  left: 0;
}

.ffHeader.inner-header {
  background: #f0eff4;
}

.ffHeader .headerAvatar img {
  width: 60px;
  height: 60px;
  margin: 0;
  border-radius: 100%;
  object-fit: cover;
}

a.ffHeaderMenu {
  color: #21285d;
}

a.ffHeaderMenu:hover {
  text-decoration: none;
}

/* MOBILE HEADER */
.ffHeader.respHeader {
  display: none !important;
}
.headerDrawerLinks .MuiAccordionDetails-root
{
  padding: 0px;
}
.headerDrawer .MuiPaper-root.MuiDrawer-paper {
  width: 300px;
}
.headerDrawerLinks .MuiAccordion-root:before
{
  display: none;
}
.headerDrawerLinks .MuiAccordion-root
{
  box-shadow: none;
}
.headerDrawerLinks .MuiAccordion-root .MuiListItem-root
{
  font-size: 16px;
  color: #394256;
}
.headerDrawerLinks .MuiAccordion-root .MuiListItem-root .material-icons
{
  color: #636363;
  padding-right: 10px;
}
.headerDrawerLinks .MuiAccordionSummary-root.MuiAccordionSummary-root
{
  padding: 3px 20px !important;
}
.headerDrawerLinks .MuiAccordion-root.Mui-expanded
{
  margin: 0px !important;
}
.headerDrawerLinks .MuiAccordionDetails-root ul
{
  margin-bottom: 0px;
  list-style-type: none;
}
.headerOdd a
{
  display: flex;
  align-items: center;
}
.headerDrawerLinks .MuiAccordionDetails-root .MuiListItem-root a
{
  display: flex;
  align-items: center;
}
.headerDrawerLinks .MuiAccordionSummary-root.MuiAccordionSummary-root .MuiAccordionSummary-content
{
  margin: 0px !important;
}
.headerDrawer .MuiPaper-root.MuiDrawer-paper .MuiListItem-root
{
  padding-left: 0px;
  margin-bottom: 5px;
}
.headerDrawer .ffLogoHead {
  padding: 20px 30px;
}

.headerDrawer .hdcAvatar img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 0;
}

.headerDrawer .headerDrawerLinks a,
.headerDrawer .hdcAvatar a {
  font-size: 16px;
  color: #394256;
  font-family: "Arial";
  text-decoration: none;
}

.headerDrawer
  .headerDrawerLinks
  a
  .MuiButtonBase-root.MuiListItem-root
  span.material-icons,
.headerDrawer
  .headerDrawerLinks
  a
  .MuiListItem-gutters.MuiListItem-root
  span.material-icons {
  padding-right: 10px;
  color: #636363;
}

.headerDrawer .hdcAvatar {
  padding: 15px 20px;
  margin-top: 20px;
  display: flex;
}

.headerDrawer .hdcAvatar a {
  padding: 20px 0;
  margin-left: 10px;
}

.headerDrawer .headerDrawerLinks a .MuiButtonBase-root.MuiListItem-root {
  border-radius: 20px;
  padding: 15px 20px;
}

.headerDrawer .headerDrawerLinks a.active .MuiButtonBase-root.MuiListItem-root {
  background: #76bc431c;
  color: var(--primColor);
}

.headerDrawer .headerDrawerLinks a.active span.material-icons {
  color: var(--primColor) !important;
}

.headerDrawer .headDrawerClose {
  padding: 0;
  width: 40px;
  min-width: auto;
  height: 40px;
  border-radius: 100%;
}
@media screen and (max-width: 1430px) {
  .ffHeader .ffnavLinks a {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 1350px) {
  .ffHeader .ffnavLinks a {
    margin-right: 2.5em;
  }
}
@media screen and (max-width: 1100px) {
  .ffHeader .ffnavLinks a {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 1050px) {
  .ffHeader .ffnavLinks a {
    margin-right: 1.1em;
  }
}
.news_image {
  display: block;
}
.news_image_mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .headerDrawerLinks .footerSocialLinks {
    justify-content: center !important;
    margin: 15px auto;
  }
  .headerDrawerLinks .footerSocialLinks a {
    border: 1px solid var(--primColor);
    border: 1px solid var(--primColor);
    border-radius: 100%;
    padding: 8px;
    width: 42px;
    height: 42px;
    display: grid;
    place-content: center;
    margin-right: 8px;
    margin-left: 8px;
  }
  .ffHeader.deskHeader {
    display: none !important;
  }
  .ffHeader.respHeader {
    display: flex !important;
  }

  .hstatic-wrap {
    border-top: 1px solid #ccc;
  }

  .hstatic-wrap a {
    display: block;
    padding: 10px 20px;
  }

  .news_image {
    display: none;
  }
  .news_image_mobile {
    display: block;
  }

  .custom-modal.newsPopupHome .popup-dialog {
    margin: 4.75rem auto !important;
  }

  .custom-modal.newsPopupHome .modal-content {
    width: 100%;
  }

  .custom-modal.newsPopupHome .modal-content .news_image img,
  .custom-modal.newsPopupHome .modal-content .news_image_mobile img {
    width: auto;
    max-width: 100%;
    height: 55vh !important;
  }
}

@media (max-width: 600px) {
  .custom-modal.newsPopupHome .modal-content {
    max-width: 80vw;
  }

  .news_image_mobile img {
    width: 100% !important;
    height: 100% !important;
  }
}
